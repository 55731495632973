import React from "react";
import { Box, Drawer, List, ListItem, ListItemIcon, styled } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import "../../blocks/dashboard/src/sidebar.css"
import { Link } from "react-router-dom";
import {
     logo, home, homeActive, budget, budgetActive, logout, investment, investmentActive, estatePlanningActive,
    contact4,message,schedule,services,clients
} from "../../blocks/dashboard/src/assets"

export default class PlannerSidebar extends React.Component {

    state = {
        activeMenu: "home",
        activeSubMenu: "networth",
        currentPath: window.location.pathname,
        openModel: false,
        isDrawerOpen: false
    };


    logout = () => {
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('authToken');
        window.localStorage.removeItem('auhtToken');
        window.localStorage.removeItem('loggedin');
        window.localStorage.removeItem('rememberMe');
        window.localStorage.removeItem('email');
        window.localStorage.removeItem('userEmail');
        window.localStorage.removeItem('profileUpdateToken');
        window.location.href = '/Loginplanner';
    }

    dialogModel = () => {
        this.setState({ openModel: !this.state.openModel })
    }

    onCancel = () => {
        this.setState({ openModel: !this.state.openModel })
    }

    DrawerOnClose = () => {
        this.setState({ isDrawerOpen: false })
    }

    openDrawer = () => {
        this.setState({ isDrawerOpen: true })
    }

    dialogPaperStyle = {
        width: 'max-content',
        backgroundColor: '#3c3c51',
        borderRadius: '12px',
        border: '1px solid #57575d',
        padding: '19px 23px',
    };

    sidebarContent = () => {
        return (
            <div className="page-sidebar" style={this.state.isDrawerOpen ? {display: "block"} : {}}>
                    <div className="site-logo" style={{marginBottom:'80px'}}>
                        <Link to="/Homepage" onClick={this.DrawerOnClose}>
                            <img className="desktop-logo1" src={logo} alt='logo' />
                        </Link>
                    </div>
                     
                    <div className="navigation">
                        <nav className="main-nav">
                                <ul className="menu">
                                    <li className={window.location.pathname == '/Homepage' ? 'active' : ''}>
                                        <Link to="/Homepage" onClick={this.DrawerOnClose}>
                                            <span className="nav-span">
                                                <img src={window.location.pathname == '/Homepage' ? homeActive : home} style={{ height: "20px" }} />
                                                Home
                                            </span></Link>
                                      
                                    </li>

                                    <li className={window.location.pathname == '/Clients'  ? 'active' : ''}>
                                        <Link to="/Clients" onClick={this.DrawerOnClose}>
                                            <span >
                                                <img src={window.location.pathname == '/Clients' ? contact4 : clients} style={{ height: "20px" }} />
                                                Clients
                                            </span></Link>
                                    
                                    </li>
                                    <li className={window.location.pathname == '/Payment' ? 'active' : ''}>
                                        <Link to="/Payment" onClick={this.DrawerOnClose}>
                                            <span>
                                                <img src={window.location.pathname == '/Payment' ? budgetActive : budget} style={{ height: "20px" }} />
                                                Payment
                                            </span></Link>
                                    
                                    </li>
                                    <li className={window.location.pathname == '/Services' ? 'active' : ''}>
                                        <Link to="/Services" onClick={this.DrawerOnClose}>
                                            <span>
                                                <img src={window.location.pathname == '/Services' ? investmentActive : services} style={{ height: "20px" }} />

                                                Services
                                            </span>
                                        </Link>
                                    </li>
                                    <li className={window.location.pathname == '/Messages' ? 'active' : ''}>
                                        <Link to="/Messages" onClick={this.DrawerOnClose}>
                                        <span>
                                            <img src={window.location.pathname == '/Messages' ? estatePlanningActive : message} style={{ height: "20px" }} />

                                            Messages
                                        </span>
                                        </Link>
                                      
                                    </li>
                                    <li className={ window.location.pathname == '/Schedule' ? 'active' : ''}>
                                        <Link to="/Schedule" onClick={this.DrawerOnClose}>
                                        <span>
                                            <img src={window.location.pathname == '/Schedule' ? estatePlanningActive : schedule} style={{ height: "20px" }} />

                                            Schedule
                                        </span>
                                        </Link>
                                      
                                    </li>
                                    <li>
                                    
                                        <span onClick={() => { this.onCancel() }} style={{color:'#EE5353',cursor:'pointer',height: "20px" }}>
                                        <img src={logout} style={{ height: "20px" }} />
                                         Logout
                                        </span>
                                   
                                    </li>
                                </ul>
                           
                        </nav>
                    </div>
                  
                    <Dialog
                        open={this.state.openModel}
                        keepMounted
                        data-test-id="dialogModel"
                        onClose={this.dialogModel}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                        PaperProps={{ style: this.dialogPaperStyle }}
                    >
                        <DialogTitle id="alert-dialog-slide-title" style={{ padding: '0px 65px',textAlign: 'center', }}>
                            <span style={{
                                color: '#fff',
                                textAlign: 'center',
                                fontSize: '13px',
                                fontFamily: 'Poppins',
                                fontWeight: 600,
                            }}>Confirm Logout ?</span></DialogTitle>
                        <DialogContent style={{
                            padding: '0px!important',
                            marginBottom: '0px',
                            paddingTop: '15px'
                        }}>
                            <DialogContentText id="alert-dialog-slide-description"
                                style={{
                                    color: 'rgb(255, 255, 255) !important',
                                    fontSize: '20px !important',
                                    fontFamily: 'Poppins !important',
                                    fontWeight: 500,
                                    marginBottom: '0px',
                                }}>
                                <div className="modal-button-container">
                                    <div className="modal-button modal-save" data-test-id='popup-save-click' onClick={() => this.onCancel()}>
                                        Cancel
                                    </div>
                                    <div className="modal-button modal-discard" data-test-id='popup-discard-click' onClick={() => this.logout()}>
                                        Log Out
                                    </div>
                                </div>
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                </div>
        )
    }

    render() {
        return (
            <StyleWrapper>
                <HamBurgerBtn onClick={this.openDrawer}>
                    <div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </HamBurgerBtn>
                {this.state.isDrawerOpen ? <Drawer PaperProps={{style: { background: "transparent" }}} anchor={"left"} open={this.state.isDrawerOpen} onClose={this.DrawerOnClose}>
                    {this.sidebarContent()}
                </Drawer>: this.sidebarContent()}
            </StyleWrapper>
        );
    }
}

const StyleWrapper = styled(Box)({
    minHeight: "100vh",
    "@media (max-width: 767px)": {
        "& .page-sidebar": {
           display: "none" 
        }
    }
})

const HamBurgerBtn = styled(Box)({
    display: "none",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    top: "50px", 
    left: 0, 
    background: "rgb(60, 60, 80)",
    cursor: "pointer",
    width: "50px",
    height: "50px",
    borderRadius: "0 10px 10px 0",
    "& div div": {
        width: "35px",
        height: "5px",
        backgroundColor: "#2ce2d5",
        margin: "6px 0",
    },
    "@media (max-width: 767px)": {
        display: "flex"
    }
})