Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "freetrial";
exports.labelBodyText = "freetrial Body";

exports.btnExampleTitle = "CLICK ME";

exports.languageListLogin = {
  English: {
    premium: 'Premium',
   
  },
  Arabic: {
    premium: 'متميز',
    
  }
}
// Customizable Area End