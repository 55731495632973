import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall } from "../../../components/src/Common";
import { getStorageData } from 'framework/src/Utilities';
import { Expired, Pending, Actve } from "../src/assets";
import moment from "moment";
import toast from "react-hot-toast";
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    location: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    token: string;
    subscription: string
    days_leftPlan: string;
    subscriptionData?: {
        id: number,
        account_id: number,
        myne_payment_method_id: number,
        plan_id: number,
        expiration_date: string,
        created_at: string,
        updated_at: string,
        cancle_status: string | null,
        payment: string | null,
        status: string,
        stripe_subscription_id: string,
        purchase_type: string,
    };
    planList:{
        id: string,
        type: string,
        attributes: {
            name: string,
            currency: string,
            duration: string | null,
            amount: number,
            frequency: string
        }
    }[],

    planData: {
        id: string,
        name: string,
        amount: number,
        currency: string,
        stripe_product_id: string,
        frequency: string,
        stripe_plan_id: string
    },
    cardDetails: {
        card_number: string,
        id: number,
        bank_logo_url: string,
        card_name: string,
        account_id: number,
        primary_card: boolean,
        expires_on: string,
        active: boolean,
        stripe_payment_method_id: string
    },
    billingDetails: {
        id: string,
        type: string,
        attributes: {
            id: number,
            name: string,
            address: string,
            city: string,
            zipcode: string,
            country: string,
            account_id: number,
            wealth_planner_id: number | null
        }
    } | null,
    openCashModel: boolean,
    // Customizable Area Start

    // Customizable Area End
}

interface SS {
    id: any;
}

export default class ManageSubscriptionController extends BlockComponent<Props, S, SS> {
    getProductApiCallId: any;
    reactInvestmentsApiCallId: string = '';
    postUserSelectDataCallId: string = '';
    postUsergetSubscriptionConfirmId: string = '';
    getBillingDetailsDataCallId: string = "";
    getPlanListDataCallId: string = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area Start

            // Customizable Area End
        ];

        this.state = {
            openCashModel: false,
            token: "",
            subscription: "",
            days_leftPlan: "",
            subscriptionData: {
                id: 0,
                account_id: 0,
                myne_payment_method_id: 0,
                plan_id: 0,
                expiration_date: "",
                created_at: "",
                updated_at: "",
                cancle_status: null,
                payment: null,
                status: "",
                stripe_subscription_id: "",
                purchase_type:""
            },
            planData:{
                amount: 0,
                currency:"",
                frequency:"",
                id:"",
                name:"",
                stripe_plan_id:"",
                stripe_product_id:""
            },
            planList:[],
            cardDetails: {
                account_id: 0,
                active: false,
                bank_logo_url: "",
                card_name: "",
                card_number: "",
                expires_on: "",
                id: 0,
                primary_card: false,
                stripe_payment_method_id: ""
            },

            billingDetails: null
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.postUserSelectDataCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
            let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            if ("prompt" in responseJson) {
                this.setSubscriptionStatus(responseJson.subscription.status, responseJson.plan.frequency );
                this.setState({days_leftPlan: moment.utc(responseJson.subscription.expiration_date).diff(moment.utc(), "days").toString(), subscriptionData: responseJson.subscription, planData: responseJson.plan, cardDetails: responseJson.card })
            }
        }
        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.postUsergetSubscriptionConfirmId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
            let responseJsonConfirm = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            if (responseJsonConfirm.message) {
                this.setState({ openCashModel: false }, ()=>{
                    this.getsubscription()
                })
                this.handleSubType(responseJsonConfirm)
                this.props.navigation.navigate('MyneSettings')
            }
        }

        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getBillingDetailsDataCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
            let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            this.setState({ billingDetails: responseJson?.data?.id ? responseJson?.data : null })
        }
        if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getPlanListDataCallId !== null &&
            this.getPlanListDataCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {
            let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            this.setState({ planList: responseJson?.data || [] })
        }
      

        // Customizable Area End
    }
    // Customizable Area Start
    getImage = (TrialText: React.FC<{ children: React.ReactNode }>, DaysLeft: React.FC<{ children: React.ReactNode }>, webStyle: { imgCss: Object }) => {
        switch (this.state.subscription) {
            case "active":
                return <img style={webStyle.imgCss} src={Actve} />;
            case "expired":
                return <img style={webStyle.imgCss} src={Expired} />;
            case "pending":
                return <img style={webStyle.imgCss} src={Pending} />;
            case "canceled":
                return <img style={webStyle.imgCss} src={Expired} />;
            case "suspended":
                return <img style={webStyle.imgCss} src={Expired} />;
            case "failed":
                return <img style={webStyle.imgCss} src={Expired} />;
            case "trial":
                return (
                    <>
                        <TrialText>{this.state.days_leftPlan}</TrialText>
                        <DaysLeft>DAYS LEFT</DaysLeft>
                    </>)
            default:
                return <img src={Actve} />;
        }
    };

    handleSubType(responseJsonConfirm: { message: string }) {
        toast(responseJsonConfirm.message, { duration: 2000 })
    }

    setSubscriptionStatus = (status: string, frequency: string) => {
        this.setState({
            subscription: status === "active" && frequency === "trial" ? "trial" : status
        })
    }
    nameInfo = (
        EnjoyingName: React.FC<{ children: React.ReactNode }>,
        ExpiredName: React.FC<{ children: React.ReactNode }>,
        PendingName: React.FC<{ children: React.ReactNode }>,
        EnjoyingNameDay: React.FC<{ children: React.ReactNode }>
    ) => {
        switch (this.state.subscription) {
            case "active":
                return <EnjoyingName>Congratulations, you’re enjoying the Premium Benefits</EnjoyingName>;
            case "expired":
                return <ExpiredName>Renew now to access extra features with Premium Subscription</ExpiredName>;
            case "pending":
                return <PendingName>There was an issue with your payment, add a new payment method or check with your bank</PendingName>;
            case "trial":
                return <EnjoyingNameDay>Congratulations, you're using the 30 Day Trial Plan</EnjoyingNameDay>;
            case "canceled":
                return <ExpiredName>Re-subscribe now to access extra features</ExpiredName>;
            case "suspended":
                return <ExpiredName>Your account has been suspended</ExpiredName>;
            case "failed":
                    return <ExpiredName>Your subscription was failed</ExpiredName>;
            default:
                return <EnjoyingName>Congratulations, you’re enjoying the Premium Benefits</EnjoyingName>;
        }
    };


    colorCss = (webStyle: { cardColor: Object, cardExpired: Object, cardPending: Object }) => {
        switch (this.state.subscription) {
            case "active":
                return webStyle.cardColor;
            case "expired":
                return webStyle.cardExpired;
            case "pending":
                return webStyle.cardPending;
            case "canceled":
                return webStyle.cardExpired;
            case "suspended":
                return webStyle.cardExpired;
            case "failed":
                return webStyle.cardExpired;
            default:
                return webStyle.cardColor;
        }
    };

    pendingButton = (webStyle: { PremiumNameButton: Object, ExpiredButton: Object, PendingButton: Object }) => {
        switch (this.state.subscription) {
            case "active":
                return webStyle.PremiumNameButton;
            case "expired":
                return webStyle.ExpiredButton;
            case "canceled":
                return webStyle.ExpiredButton;
            case "pending":
                return webStyle.PendingButton;
            case "suspended":
                return webStyle.ExpiredButton;
            case "failed":
                return webStyle.ExpiredButton;
            default:
                return webStyle.PremiumNameButton;
        }
    };
    allButtonName = () => {
        switch (this.state.subscription) {
            case "active":
                return "Active";
            case "expired":
                return "Expired";
            case "pending":
                return "Pending";
            case "canceled":
                return "Canceled";
            case "suspended":
                return "Suspended";
            case "failed":
                return "Failed";
            default:
                return "Active";
        }
    };
    async componentDidMount() {
        try { const token = await getStorageData('token'); this.setState({ token: token }) } catch (error) { }
        this.getsubscription()
        this.getBillingDetails()
        this.getPlanList()
    }
    getPlanList = async () => {
        const header = { "token": this.state.token };
        let userDetailurl = "bx_block_stripe_integration/subscriptions/list_plan";
        const postPdfData = apiCall({
            header: header,
            httpBody: {},
            url: userDetailurl,
            httpMethod: configJSON.exampleAPiMethodtokenSubscription,
        });
        this.getPlanListDataCallId = postPdfData.messageId;
        runEngine.sendMessage(postPdfData.id, postPdfData);
    }

    getBillingDetails = async () => {
        const header = { "token": this.state.token };
        let userDetailurl = "bx_block_stripe_integration/subscriptions/show_billing_address";
        const postPdfData = apiCall({
            header: header,
            httpBody: {},
            url: userDetailurl,
            httpMethod: configJSON.exampleAPiMethodtokenSubscription,
        });
        this.getBillingDetailsDataCallId = postPdfData.messageId;
        runEngine.sendMessage(postPdfData.id, postPdfData);
    }

    getsubscription = async () => {
        const header = { "token": this.state.token };
        let userDetailurl = configJSON.subscriptionApi;
        const postPdfData = apiCall({
            header: header,
            httpBody: {},
            url: userDetailurl,
            httpMethod: configJSON.exampleAPiMethodtokenSubscription,
        });
        this.postUserSelectDataCallId = postPdfData.messageId;
        runEngine.sendMessage(postPdfData.id, postPdfData);
    }

    getsubscriptionConfirm = async () => {
        const header = { "token": this.state.token };
        let userDetailurl = configJSON.subscriptionCancelApi;
        const postPdfData = apiCall({
            header: header,
            httpBody: {},
            url: userDetailurl,
            httpMethod: configJSON.getSubscriptionAPiMethod,
        });
        this.postUsergetSubscriptionConfirmId = postPdfData.messageId;
        runEngine.sendMessage(postPdfData.id, postPdfData);
    }

    closeButton = () => {
        {this.state.subscriptionData?.purchase_type === 'in_app' ? toast.error("This is an in-app purchase subscription. Please try to cancel it on an iOS device."): this.setState({ openCashModel: true })}
    }
    dialogCashModel = () => {
        this.setState({ openCashModel: !this.state.openCashModel })
    }
    dialogCashPaperStyle = {
        width: 'max-content',
        backgroundColor: '#3b3c50',
        borderRadius: '8px',boxShadow: '0px 0px 10px 0px rgba(44, 226, 213, 0.16)',
    };

    changeSubscription = () => {
        this.props.navigation.navigate('ChangePlan')
    }
    renewSubscription = () => {
        this.props.navigation.navigate('ChangePlan')
    }
    aedMonth = (plan_id: string) => {
        this.props.navigation.navigate('PaymentsFreeTrial', {id: plan_id})
    }
    editDetails = () => {
        let id = this.state.subscriptionData?.plan_id
        this.props.navigation.navigate(`EditPayment`,{id:id})
    }
    retryPayment = () => {
        this.props.navigation.navigate('ChangePlan')
    }
    // Customizable Area End
}

