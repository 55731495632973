import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import * as Yup from 'yup';
import storage from "framework/src/StorageProvider";
import { FormikValues } from "formik";
import toast from "react-hot-toast";
import { getStorageData } from "framework/src/Utilities";
export const liabilityConfigJSON = require("./config");

 export interface ICurrency{
    "id": number,
    "currency_name": string,
    "value_respect_to_usd": number,
    "currency_type": string,
    "symbol": string,
  }
interface ILiabilityObject {
    id: string,
    type: string,
    attributes: {
      id: number,
      name: string,
      amount: number,
      currency_type: string | null,
      term: string,
      months: number,
      annual_interest_rate: number,
      monthly_payments: number,
      start_date: string,
      first_payments: number,
      form_type: string,
      property_id: number,
      portable_asset_id: number,
      converted_amount: string,
      converted_currency_type: string,
      converted_monthly_payments: number,
      converted_currency_symbol: string,
      remaining_payment: string,
      remaining_month: number,
    }
  }

interface IPropertyAssetDataObject {
    id: string,
    type: string,
    attributes: {
        name: string,
        price_currency: string,
        mortgage_currency: string | null,
        location: string,
        tower_or_community: string | null,
        apartment_number: string,
        floor_number: number,
        bed_rooms: number,
        bath_rooms: number,
        account_id: number,
        market_value: number,
        purchase_date: string,
        martagage_loan: boolean,
        market_date: string,
        country: string,
        operand: string,
        converted_market_value: number,
        price: string,
        mortgage: string | null,
        area_size: number,
        converted_price: string,
        balance: string | null,
        converted_currency_symbol: string,
        converted_currency: string,
        converted_mortgage: string | null,
        mortgage_currency_symbol: string | null,
        images: string | null,
        title_deeds: string | null,
        status: {
            id: number,
            name: string
        },
        sub_category: {
            id: number,
            name: string,
            image: {
                id: number,
                url: string,
                filename: string,
                content_type: string
            }
        },
        property_mortgage: {
            id: number,
            amount: number,
            term_years: string,
            annual_interest_rate: number,
            mortgage_monthly_payments: number,
            start_date: string,
            first_loan_payment_date: string,
            property_id: number,
            created_at: string,
            updated_at: string,
            term_months: number
        } | null,
        profit_percentage: number,
        profit: number,
        liabilities_forms: {
            id: number,
            name: string,
            amount: number,
            term: string,
            months: number,
            annual_interest_rate: number,
            monthly_payments: number,
            start_date: string,
            first_payments: number,
            first_loan_payment_date: string,
            account_id: number,
            form_type: string,
            property_id: number,
            currency_type: string,
            converted_currency_type: string,
            converted_amount: number,
            converted_monthly_payments: number,
            portable_asset_id: number | null,
            created_at: string,
            updated_at: string
        }
    }
}

interface IPortableAssetDataObject {
    id: string,
    type: string,
    attributes: {
        name: string,
        price_currency: string,
        loan_currency: string | null,
        model: string,
        account_id: number,
        country: string,
        market_date: string,
        purchase_date: string,
        market_value: string,
        loan_available: string | null,
        loan_status: boolean,
        price: string,
        converted_price: string,
        balance: string,
        converted_loan: string | number,
        converted_currency_symbol: string,
        converted_currency: string,
        profit: number,
        profit_percentage: number,
        converted_market_value: string,
        operand: string,
        images: string | null,
        receipts: string | null,
        warrants: string | null,
        sub_category: {
            id: number,
            name: string,
            image: {
                url: string,
                filename: string,
                content_type: string
            }
        },
        loan_detail: {
            id: number,
            amount: number,
            term_years: string,
            term_months: string,
            annual_interest_rate: number,
            loan_monthly_payments: number,
            start_date: string,
            first_loan_payment_date: string,
            portable_asset_id: number,
            created_at: string,
            updated_at: string
        } | null,
        liability: {
            id: number,
            name: string,
            amount: number,
            term: string,
            months: number,
            annual_interest_rate: number,
            monthly_payments: number,
            start_date: string,
            first_payments: number,
            first_loan_payment_date: string,
            account_id: number,
            form_type: string,
            property_id: number | null,
            currency_type: string,
            converted_currency_type: string | null,
            converted_amount: string | number | null,
            converted_monthly_payments: number | null,
            portable_asset_id: number | null,
            created_at: string,
            updated_at: string
        } | null
    }
}


interface IPayload {
    name: string;
    amount: number;
    term: number;
    months: number;
    annual_interest_rate: number;
    monthly_payments: string;
    start_date: string
    first_payments: string;
    form_type: string;
    property_id?: string | null;
    portable_asset_id?: string | null;
    liability_id?: string | null;
    currency_type: string;
}
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    currencyList: ICurrency[],
    selectedCurrency: {
        label: string,
        value: string
    },
    language:string,
    direction: string,
    selectedLiabilityType: number;
    propertyAssetData: IPropertyAssetDataObject[],
    portableAssetData: IPortableAssetDataObject[],
    editLiabilityForm: boolean,
    openSuccessModal: boolean,
    editLiabilityFormData: ILiabilityObject | null,
    significantValue:string
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class LiabilityController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    postUserSelectDataCallId: string = "";
    putCurrencyAPICallId: string = "";
    getCurrencyListCallId: string = "";
    getPropertyAssetCallId: string = "";
    postLiabilityFormDataCallId: string = "";
    getPortableAssetCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        // Customizable Area End

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseData),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            currencyList: [],
            selectedCurrency: { label: "", value: "" },
            direction: "ltr",
            language: "English",
            selectedLiabilityType: this.LiabilityType.Mortgages,
            propertyAssetData: [],
            portableAssetData: [],
            editLiabilityForm: false,
            openSuccessModal: false,
            editLiabilityFormData: null,
            significantValue:''
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async componentDidMount() {
        this.getCurrencyList();
        this.loadPropertyAssetData();
        this.getPortableAssetData();

        let language = await getStorageData('language');
        const currentLanguage = language === 'Arabic' ? 'rtl' : 'ltr';
        if(!language){
        language = "English"
        }
        this.setState({ direction: currentLanguage ,language: language});
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
        const dataMessage = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        this.handleGetCurrencyListResponse(apiRequestCallId,responseJson);
        this.handlePropertyAssetData(dataMessage, message);
        this.handlePortableAssetData(apiRequestCallId, responseJson)
        this.handleCreateLiabilityResponse(apiRequestCallId);
        this.handleManageCurrencyResponse(apiRequestCallId);
        this.handleNavigationMessage(message);
        // Customizable Area End
    }

    // Customizable Area Start

    apiCall = async (data: {
        contentType: string, method: string, endPoint: string, body: string | null 
      }) => {
        const token = await storage.get("authToken")
        const { contentType, method, endPoint, body } = data;
        const liabilityMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage));
    
        liabilityMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify({
            "Content-Type": contentType,
            token
          })
        );
    
        liabilityMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          method
        );
    
        liabilityMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
        );
    
        body && liabilityMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          body
        );
        runEngine.sendMessage(liabilityMessage.id, liabilityMessage);
        return liabilityMessage.messageId;
      };

    getCurrencyList = async() => {
    this.getCurrencyListCallId = await this.apiCall({
        contentType:"application/json" + '',
        method: "GET" + '',
        endPoint: "bx_block_profile/profiles/currency_list" ,
        body: null
    });
    }

    handlePortableAssetData = async (apiRequestCallId: string, responseJson: {data: IPortableAssetDataObject[], message: string}) => {
        if (apiRequestCallId === this.getPortableAssetCallId) {
            const portableAssetData = responseJson?.data || [];
            this.setState({
                portableAssetData
            })
            if (this.getFormType().value === this.LiabilityType.PersonalLoan && portableAssetData.length === 0) {
                toast.error("No Linked Associated Investment Found.")
            }
        }
    }

    handleGetCurrencyListResponse(apiRequestCallId:string,responseJson: {
        list:ICurrency[],
        selection: number
      }){
    
        if(apiRequestCallId === this.getCurrencyListCallId){
          const userSelectedCurrncy = responseJson.list.find((value) => {
            return value?.id == responseJson.selection
        })
          this.setState({
            currencyList: responseJson.list,
            selectedCurrency: userSelectedCurrncy? { value: userSelectedCurrncy.currency_type, label: `${userSelectedCurrncy.symbol}${userSelectedCurrncy.currency_type}` }:{
              value:"",label:""
            },
           
          })
        }
      }

    async loadPropertyAssetData() {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getPropertyAssetCallId = requestMessage.messageId;
        const headers = {
            token: await storage.get("authToken")
        };
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `account_block/properties`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'GET'
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleManageCurrencyResponse(apiRequestCallId:string){
        if(apiRequestCallId === this.putCurrencyAPICallId){
          this.getCurrencyList()
        }
      }

    handlePropertyAssetData(dataMessage: string, message: Message) {
        if (dataMessage === this.getPropertyAssetCallId) {
            let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const propertyAssetData = responseJson?.data || []
            this.setState({
                propertyAssetData
            })
            if (this.getFormType().value === this.LiabilityType.Mortgages && propertyAssetData.length === 0) {
                toast.error("No Linked Associated Investment Found.")
            }
        }
    }

    handleInvestmentChange = (values: {label: string, value: string}, setFieldValue: (field: string, value: string | number | {label: string, value: string} | Date | null) => void) => {
        setFieldValue("associatedInvestment", values)
        if (this.getFormType().value === this.LiabilityType.Mortgages) {
            const selectedInvestment = this.state.propertyAssetData.find(value => value.id === values.value)
            if (selectedInvestment && selectedInvestment.attributes.liabilities_forms) {
                const { id, name, amount, converted_amount, term, months, annual_interest_rate, converted_monthly_payments, start_date, first_loan_payment_date, currency_type} = selectedInvestment.attributes.liabilities_forms
                setFieldValue("name", name)
                setFieldValue("liabilityId", id)
                setFieldValue("term", term)
                setFieldValue("amount", converted_amount || amount)
                setFieldValue("months", months)
                setFieldValue("annualInterestRate", annual_interest_rate)
                setFieldValue("monthlyPayments", converted_monthly_payments)
                setFieldValue("startDate", new Date(start_date))
                setFieldValue("currency_type", {label: currency_type, value: currency_type})
                setFieldValue("firstPayment", first_loan_payment_date)
                this.calculateMortgageMonthlyPayment({amount,annualInterestRate: annual_interest_rate, term, months}, setFieldValue)
            }
        } else if (this.getFormType().value === this.LiabilityType.PersonalLoan) {
            const selectedInvestment = this.state.portableAssetData.find(value => value.id === values.value)
            if (selectedInvestment && selectedInvestment.attributes.liability) {
                const { id, name, amount, converted_amount, term, months, annual_interest_rate, converted_monthly_payments, start_date, first_loan_payment_date, currency_type} = selectedInvestment.attributes.liability
                setFieldValue("name", name)
                setFieldValue("liabilityId", id)
                setFieldValue("amount", converted_amount || amount)
                setFieldValue("term", term)
                setFieldValue("months", months)
                setFieldValue("annualInterestRate", annual_interest_rate)
                setFieldValue("startDate", new Date(start_date))
                setFieldValue("monthlyPayments", converted_monthly_payments)
                setFieldValue("firstPayment", first_loan_payment_date)
                setFieldValue("currency_type", {label: currency_type, value: currency_type})
                this.calculateMortgageMonthlyPayment({amount,annualInterestRate: annual_interest_rate, term, months}, setFieldValue)
            }
        }
    }

    navigateRoute = (route: string) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), route);
        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message)
    }

    LiabilityType = {
        Mortgages: 1,
        CreditCard: 2,
        PersonalLoan: 3,
        Other: 4
    }

    dialogSuccessPaperStyle = {
        width: 'max-content',
        backgroundColor: '#395D6B',
        borderRadius: '8px',
        boxShadow: '0px 0px 10px 0px rgba(44, 226, 213, 0.16)',
      };

    getPortableAssetData = async () => {
        this.getPortableAssetCallId = await this.apiCall({
            contentType:"application/json" + '',
            method: "GET" + '',
            endPoint: "account_block/portable_assets" ,
            body: null
        });
    }

    getFormType = () => {
        switch (window.location.pathname.toLowerCase()) {
            case "/personalloanform":
                return { name: "PersonalLoan", value: this.LiabilityType.PersonalLoan };
            case "/otherloanform":
                return { name: "Other", value: this.LiabilityType.Other };
            case "/mortgageform":
            default:
                return { name: "Mortgage", value: this.LiabilityType.Mortgages };
        }
    }

    getString = (keyVal: string) => {
        let languageProperty = this.state?.language || "English"
        const formProperty = this.getFormType()
        return liabilityConfigJSON.languageListLogin[languageProperty][formProperty.name][keyVal]
    }

    setCurrency = async (value: { value: string, label: string }) => {
        const currency = this.state.currencyList.find((newValue) => {
          return newValue.currency_type === value.value
        })
        if (currency) {
          this.putCurrencyAPICallId = await this.apiCall({
            contentType: "application/json" + '',
            method: "PUT" + '',
            endPoint: "bx_block_profile/profiles/add_currency_to_account",
            body: JSON.stringify({
              currency_id: currency.id
            })
          });
        }
      }

    LiabilityFormSchema = Yup.object().shape({
        liabilityId: Yup.string().nullable(), 
        name: Yup.string()
            .required(this.getString('nameError')),
        amount: Yup.number()
        .max(999999999999999, "Amount should be 15 digits or less")
        .required(this.getString('amountError')),
        term: Yup.number()
            .integer(this.getString('termDecimalError'))
            .required(this.getString('termError')),
        months: Yup.number()
            .integer(this.getString('monthsDecimalError'))
            .min(0, this.getString('monthsMinError'))
            .max(12, this.getString('monthsMaxError'))
            .required(this.getString('monthsError')),
        annualInterestRate: Yup.number()
            .required(this.getString('annualInterestRateError')),
        monthlyPayments: Yup.number()
            .required(this.getString('monthlyPaymentsError')),
        startDate: Yup.string()
            .required(this.getString('startDateError')).nullable(),
        firstPayment: Yup.string()
            .required(this.getString('firstPaymentError'))
            .nullable(),
        associatedInvestment: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string()
        }).nullable().when([], {
            is: () => window.location.pathname !== '/OtherLoanForm',
            then: (schema: Yup.ObjectSchema<(object & { label: string; value: string; })>) => schema.required(this.getString("associatedInvestmentError")),
            otherwise: (schema: Yup.ObjectSchema<(object & { label: string; value: string; })>) => schema.nullable()
        }),
        currency_type: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string()
        }).nullable().required(this.getString("currencyTypeError"))
    })

   
    handleFormat = (value: any) => {
        if (typeof value === 'number') {
          return value.toLocaleString();
        } else if (typeof value === 'string') {
          let numValue = parseFloat(value.replace(/,/g, ''));
          if (!isNaN(numValue)) {
            return numValue.toLocaleString();
          }
        }
        return value;
      }
      removeCommas = (value:any) => {
        if (typeof value === 'number') {
          return value.toString().replace(/,/g, '');
        } else if (typeof value === 'string') {
          return value.replace(/,/g, '');
        }
        return value;
       };
      calculateMortgageMonthlyPayment = (values: FormikValues, setFieldValue: (field: string, value: string | number) => void) => {
        const rawAmount:any = parseFloat(values.amount.toString().replace(/,/g, ''));
        this.setState({significantValue: this.handleFormat(rawAmount)});
        const { annualInterestRate, months, term } = values;
        if (rawAmount && term?.toString() && months?.toString() && annualInterestRate?.toString()) {
          const monthlyInterest = annualInterestRate / (12 * 100);
          const totalPayment = term * 12 + months;
          const monthlyPayment = rawAmount * (monthlyInterest * Math.pow(1 + monthlyInterest, totalPayment)) / (Math.pow(1 + monthlyInterest, totalPayment) - 1);
          setFieldValue("monthlyPayments", monthlyPayment ? (Math.round(monthlyPayment * 100) / 100).toString() : "");
        } else {
          setFieldValue("monthlyPayments", "");
        }
      }

    formTypePayload = () => {
        const formType = this.getFormType()
        switch (formType.value) {
            case this.LiabilityType.PersonalLoan:
                return "personal_loans";
            case this.LiabilityType.Other:
                return "other";
            case this.LiabilityType.Mortgages:
            default:
                return "mortgage";
        }
    }

    handleCreateLiabilityResponse(apiRequestCallId:string) {
        if(apiRequestCallId === this.postLiabilityFormDataCallId && !this.state.editLiabilityForm){
          this.setState({
            openSuccessModal: true
          })
        } 
        if (apiRequestCallId === this.postLiabilityFormDataCallId && this.state.editLiabilityForm){
            this.navigateRoute("LiabilityAssets");
        }
    }

    handleBack = () => {
        if (this.state.editLiabilityForm) {
            this.navigateRoute("LiabilityAssets")
        } else {
            this.navigateRoute("AddLiabilityWeb")
        }
    }

    formateDate = (dateString: string) => {
        const newDate = new Date(dateString);
        return `${newDate.getDate()}-${newDate.getMonth() + 1}-${newDate.getFullYear()}`;
    }

    handleSubmit = async (values: FormikValues) => {
        const removeFunc=this.removeCommas(values.amount)
    this.setState({significantValue: removeFunc} , async ()=> {
        let payload: { data: IPayload }= {
            data: {
                name: values.name,
                amount: values.amount,
                term: values.term,
                months: values.months,
                annual_interest_rate: values.annualInterestRate,
                monthly_payments: values.monthlyPayments,
                start_date: this.formateDate(values.startDate),
                first_payments: this.formateDate(values.firstPayment),
                form_type: this.formTypePayload(),
                currency_type: values.currency_type.value,
            }
        }
        if (this.getFormType().value === this.LiabilityType.Mortgages) {
            payload.data.property_id = values.associatedInvestment.value
        } else if (this.getFormType().value === this.LiabilityType.PersonalLoan) {
            payload.data.portable_asset_id = values.associatedInvestment.value
        }
        
        if (this.state.editLiabilityForm) {
            this.postLiabilityFormDataCallId = await this.apiCall({
                contentType: "application/json" + '',
                method: "PUT" + '',
                endPoint: `account_block/liabilities_forms/${this.state.editLiabilityFormData?.id}`,
                body: JSON.stringify(payload)
            });
        } else {
            payload.data.liability_id = values.liabilityId
            this.postLiabilityFormDataCallId = await this.apiCall({
                contentType: "application/json" + '',
                method: "POST" + '',
                endPoint: "account_block/liabilities_forms",
                body: JSON.stringify(payload)
            });
        }})
    }

    handleNavigationMessage(message: Message) {
        if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
          const liability = message.getData(
            getName(MessageEnum.NavigationPayLoadMessage)
          );
          if(liability){
            this.setState({
                editLiabilityForm: true,
                editLiabilityFormData: liability
            })
          }
        }
      }

      InitData(condition: boolean, trueValue: string | number = "", falseValue: string | null = ""){
        return condition ? trueValue : falseValue;
    }
    
    InitCurrency = () => {
        const currency = this.state.currencyList.find(value => value.currency_type == this.state.editLiabilityFormData?.attributes.converted_currency_type)
        if (currency) {
            return ({label: currency.currency_type, value: currency.currency_type})
        } else {
            return this.state.selectedCurrency
        }
    }

    InitAccociatedInvestment = () => {
        let assetData = null;
        if (this.getFormType().value === this.LiabilityType.Mortgages) {
            assetData = this.state.propertyAssetData.find(value => value.id == this.state.editLiabilityFormData?.attributes.property_id.toString())
        } else if (this.getFormType().value === this.LiabilityType.PersonalLoan) {
            assetData = this.state.portableAssetData.find(value => value.id == this.state.editLiabilityFormData?.attributes.portable_asset_id.toString())
        }
        if (assetData) {
            return ({label: assetData.attributes.name, value: assetData.id})
        } else {
            return null;
        }
    }

    getInvestmentOptions = () => {
        if (this.getFormType().value === this.LiabilityType.Mortgages) {
            return this.state.propertyAssetData?.map(value => ({ label: value.attributes.name, value: value.id }))
        } else {
            return this.state.portableAssetData?.map(value => ({ label: value.attributes.name, value: value.id }))
        }
    }

    // Customizable Area End
}
