import { BlockComponent } from "../../framework/src/BlockComponent";

import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { Message } from "../../framework/src/Message";
import { runEngine } from "../../framework/src/RunEngine";
import { IBlock } from "../../framework/src/IBlock";

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  openModel: boolean;
  activeSubMenu: string;
  activeMenu: string;
  showQuestionArray: boolean;
  hasSelectedPlanner: boolean;
  serviceSelected: boolean;
  isPinPresent: boolean;
  isDrawerOpen: boolean;
  toastOpenn: boolean;
  plannedEvents: IPlannedEvent[];
  currentUniqueId: string[];
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SavedAvailabilityController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock((this as unknown) as IBlock, [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ]);
    this.state = {
      openModel: false,
      activeSubMenu: window.location.pathname,
      activeMenu: window.location.pathname,
      showQuestionArray: true,
      hasSelectedPlanner: false,
      serviceSelected: false,
      isPinPresent: false,
      isDrawerOpen: false,
      toastOpenn:false,
      plannedEvents: [],
      currentUniqueId:[],
    };
  }

  getStatusId: string = "";
  getServiceStatusId: string = ""

  getQuestionnaireStatusId: string = "";
  getPlannedEventListCallId: string = "";
  getSetUpPin: string = "";
  interval2Id: ReturnType<typeof setInterval> | number = 0;


  async receive(from: string, message: Message) {
    // Customizable Area Start

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.getQuestionnaireStatusId) {
        if(responseJson?.errors && (responseJson?.errors[0]?.token === "Invalid token" || responseJson?.errors[0]?.token === "Token has Expired" || responseJson?.errors[0].includes("No active subscription"))){
          this.logout()
        }
        if (responseJson.message === "The questions already answered") {
          this.setState({
            showQuestionArray: false,
          });
          this.getServiceStatus();
          this.getStatus()
        }
      }
      if (apiRequestCallId === this.getStatusId) {
        if(responseJson.wealth_planner)
        this.setState({
          hasSelectedPlanner: true,
        });
          
      }
      if (apiRequestCallId === this.getServiceStatusId) {
        this.setState({
          serviceSelected: responseJson.services_selected,
        });
      }
      if (apiRequestCallId === this.getSetUpPin) {
        if (responseJson) {
          if (responseJson.message === "Pin present") {
            this.setState({
              isPinPresent: true
            })
          }
          else {
            this.setState({
              isPinPresent: false
            })
          }
        }
      }
      if (apiRequestCallId === this.getPlannedEventListCallId) {
        this.handleSetPlannedEvents(responseJson.dashboard_planners.data);
      }
    }

    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.interval2Id = window.setInterval(()=>{this.checkSlots()}, 6000);
    this.getQuestionArrayStatus();
    this.getSetUpPinStatus()
    document.addEventListener("estatePlanningUpdated", () => {
      this.getQuestionArrayStatus();
    });
    this.loadPlannedEventList();
  }

  getSetUpPinStatus() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSetUpPin = requestMessage.messageId;
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_settings/settings/user_pin_status"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  logout = () => {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('userEmail');
    window.localStorage.removeItem('authToken');
    window.localStorage.removeItem('rememberMe');
    window.localStorage.removeItem('email');
    window.localStorage.removeItem('loggedin');
    window.localStorage.removeItem('auhtToken');
    window.localStorage.removeItem("slotData");
    window.localStorage.removeItem("closeData");
    this.goToEmailLogin()
}
goToEmailLogin() {
  const msg: Message = new Message(
    getName(MessageEnum.NavigationEmailLogInMessage)
  );
  msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  this.send(msg);
}
  getServiceStatus() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getServiceStatusId = requestMessage.messageId;
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_proposal_generation/user_service_selections/services_status"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getStatus() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStatusId = requestMessage.messageId;
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_formapprovalworkflow/send_requests/get_status"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getEstatePlanningLink() {
    if (this.state.showQuestionArray) {
      return "/Quetionnaire";
    }
    if (!this.state.hasSelectedPlanner) {
      return "/FinancialPlanner";
    }
    if (this.state.serviceSelected) {
      return "/EstatePlanning";
    }
    return "/EngagementRequest/id";
  }

  getQuestionArrayStatus() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getQuestionnaireStatusId = requestMessage.messageId;
    const header = {
      "Content-Type": "application/json",
      token: localStorage.getItem("token"),
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_questionbank/estate_planning_questionnaire/questionnaire_status"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  async loadPlannedEventList() {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPlannedEventListCallId = requestMessage.messageId;

    const headers = {
      token: localStorage.getItem("token")
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_proposal_generation/user_service_selections/dashboard_planners'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSetPlannedEvents = (data: IPlannedEvent[]) => {
    this.setState({
      plannedEvents: data,
    }, ()=> this.checkSlots());
  };
  handleToast() {
    const slotdata = localStorage.getItem("slotData")
    const closeData = localStorage.getItem("closeData")
    if (slotdata && JSON.parse(slotdata).length) {
      if (closeData) {
        if (!(JSON.parse(slotdata).length === JSON.parse(closeData).length && JSON.parse(slotdata).sort().every((value: string, index: number) => value === JSON.parse(closeData).sort()[index]))) 
          {
          this.setState({ toastOpenn: true })
        }
      } else {
        this.setState({ toastOpenn: true })
      }
    }
  }

  checkSlots = () => {

    const currentTime = new Date();
    const updatedPlanners = this.state.plannedEvents.map((planner) => {
      if (planner.attributes.slot) {

        const [day, month] = planner.attributes.slot.split(' ');
        const formattedStartTime = this.formatTimeFromISO(planner.attributes.slot_time);

        const [hours, minutes] = formattedStartTime.split(':');
        const monthNames = ["January", "February", "March", "April", "May", "June",
          "July", "August", "September", "October", "November", "December"];
        const monthIndex = monthNames.indexOf(month);
        const bookingDateTime = new Date(Date.UTC(currentTime.getUTCFullYear(), monthIndex, parseInt(day, 10), parseInt(hours, 10),
          parseInt(minutes, 10)));
        const currentUTCTime = new Date(Date.UTC(
          currentTime.getUTCFullYear(),
          currentTime.getUTCMonth(),
          currentTime.getUTCDate(),
          currentTime.getUTCHours(),
          currentTime.getUTCMinutes(),
          currentTime.getUTCSeconds()
        ));
        const differenceInMillis: any = (bookingDateTime instanceof Date)
          ? bookingDateTime.getTime() - currentUTCTime.getTime()
          : 1;

        const differenceInMinutes = Math.floor(differenceInMillis / (1000 * 60));

        if (differenceInMinutes <= 5 && differenceInMinutes >= -planner?.attributes?.plan?.duration) {
          return { ...planner, iscallenable: true, istimeexceeded: false, toastOpenn: true };
        } else {
          if (differenceInMinutes <= -planner?.attributes?.plan?.duration) {
            return { ...planner, iscallenable: false, istimeexceeded: true, };

          }
          if (differenceInMinutes >= 5) {
            return { ...planner, iscallenable: false, istimeexceeded: false, };

          }
        }
      }
      return (planner)
    });

    this.setState({ plannedEvents: updatedPlanners }, ()=>{
      this.checkLocalStorageData()
    })

  }

  checkLocalStorageData(){
    const newArray = this.state.plannedEvents.filter((item) => item.toastOpenn).map(item=> item.id)
    this.setState({currentUniqueId:newArray})
    if(newArray.length){
      localStorage.setItem("slotData", JSON.stringify(newArray))
    }
      this.handleToast()
  }


  formatTimeFromISO(isoString:string) {
    const date = new Date(isoString);
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
  }
  handleSidebarToastClose=()=>{
    if(this.state.toastOpenn){
      this.setState({toastOpenn:false})
      localStorage.setItem("closeData", JSON.stringify(this.state.currentUniqueId))
    }

  }

}
export interface IReview {
  date: string,
  description: string,
  rating: number,
  user_name: string,
  user_photo: string,
}
interface Plan {
  duration: number;
}
export interface IPlannedEvent {
  "id": string,
  "type": string,
  deletePopup?: boolean,
  iscallenable?: boolean,
  istimeexceeded?:boolean,
  toastOpenn:boolean,
  "attributes": {
    "planner_id": number,
    "name": string,
    "location": "indore",
    "jurisdiction_advice": "yes it is",
    "disclosure": "no",
    "firm": "witmates",
    "employment_history": "1",
    "qualification": "mca",
    "experience": number,
    "certificate": string,
    "email": string,
    "terms_and_condition": boolean,
    "url": string,
    "dob": string,
    "mobile": string,
    "focus": {
      "name": string
    }[],
    "image": string,
    "request_status": string,
    "service_completed_status": string,
    "new_services_added": boolean,
    "service_added": boolean,
    "total_reviews": number,
    "average_rating": null | number,
    "rating_and_reviews": IReview[],
    "plan": Plan,
    "slot": string |null,
    "slot_time":string,
    "slot_cancellation": null,
    "pop_up_displayed": boolean,
    "allow_access": null,
    review_status: boolean,
  }
  }