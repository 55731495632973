// Customizable Area Start
import React from "react";

// Customizable Area Start
import { styled } from '@material-ui/styles';

import {
    Container,
    Box,
    Typography,
    OutlinedInput
} from "@material-ui/core";
import { NavLink, Link } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import "../assets/border.css";
import FreeTrialController, {
    Props,
} from "./FreetrialController";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from 'react-select';
import { backIcon, card, group_Exit, deleteIcon, SuccessCard, close, info, plus, checckedIcon } from "./assets";


// Customizable Area End

// Customizable Area Start
const cashCustomStyles = {
    control: (defaultStyles: any) => (
        {
            ...defaultStyles,
            fontSize: '16px',
            textAlign: "left",
            borderRadius: '8px',
            border: '1px solid #2DF1E3 !important',
            backgroundColor: "transparent !important",
            padding: "8px",
            fontWeight: '500',
            color: 'rgba(255, 255, 255, 0.60)',
            fontFamily: 'Poppins',
        }),
    input: (defaultStyles: object) => ({ ...defaultStyles, color: '#fff' })

};
const theme = createTheme({
    palette: {
        primary: {
            main: "#2CE2D5",
            contrastText: "#fff",
        },
    },
    overrides: {
        MuiOutlinedInput: {
            root: {
                "& $notchedOutline": {
                    borderColor: "#2DF1E3",
                },
                "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
                    borderColor: "#2DF1E3",
                },
                "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
                    borderColor: "#2DF1E3 !important",
                },
                "& .MuiOutlinedInput-input": {
                    width: '100%',
                    borderBottom: '0px',
                    color: "white",
                }
            },
        },
    },
});
// Customizable Area End

export default class EditPayment extends FreeTrialController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }
    getPlanType(subscriptionDetails: {
        plan: {
            frequency: string,
            amount: number
        }
    }) {
        if (subscriptionDetails?.plan.frequency.startsWith("trial")) {
            return "Free trial";
        } else if (subscriptionDetails?.plan.frequency.startsWith("year")) {
            return `AED ${subscriptionDetails.plan.amount}/yr`;
        } else {
            return `AED ${subscriptionDetails.plan.amount}/mo`;
        }
    }
    currenPlanDetails() {
        return (
            <Box style={webStyle.logoandimg1 as React.CSSProperties} dir={this.state.logoImg1Login} data-test-id="currenctPlanDetails">
                <div><p>Current Plan <br /><span style={{ color: "#40e0d0" }}><b>{this.state.subscriptionDetails && this.state.subscriptionDetails.plan.frequency.startsWith("trial") && this.state.subscriptionDetails.subscription.status === "active" ? "30 Day trial" : "Premium"}</b></span></p></div>
                <div><p>Plan Type <br /><b>{this.state.subscriptionDetails && this.getPlanType(this.state.subscriptionDetails)}</b></p></div>
                <div><p>Next Billing <br /><b>
                    {new Date(this.state.subscriptionDetails?.subscription.expiration_date!).toLocaleDateString("en-GB")}
                </b></p></div>
            </Box>
        )
    }
    selectMenuForEditPaymentScreen(customStyles: any) {
        let valueForCountry = { value: "", label: "" };
        if (this.state.paymentMethod == 'edit_address') {
            valueForCountry = { value: this.state.addAddressFormDetails.country, label: this.state.address.attributes.country }
        }
        return (
            <Select
                data-test-id="selectMenu"
                options={this.state.countryName}
                onChange={(e) => this.updateAddAddressDetails('country', e)}
                defaultValue={valueForCountry} placeholder={"Select country"}
                classNamePrefix="an-simple-select" className="selectMenu" styles={customStyles} />
        )
    }

    formRender1() {
        return (
            <Box>
                {
                    this.state.paymentMethod == 'add_address' &&
                    <>
                        <Box>
                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography style={webStyle.formHeader1}>Add Address</Typography>
                            </Box>
                        </Box>
                        <Box  className="custom_address_form1">
                            <Grid container spacing={2}>
                                <Grid item xs={12} >
                                    <Typography style={webStyle.card_input_message1}>Name:</Typography>
                                    <OutlinedInput
                                        style={{ ...webStyle.inputRootForPayments, marginTop: '12px' }}
                                        data-test-id="addAddressName"
                                        className="inputBox"
                                        fullWidth={true}
                                        onChange={(e) => this.updateAddAddressDetails('name', e.target.value)}
                                        placeholder="Enter Name"
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    <Typography style={webStyle.card_input_message1}>Address:</Typography>
                                    <OutlinedInput
                                        style={{ ...webStyle.inputRootForPayments, marginTop: '12px' }}
                                        className="inputBox"
                                        data-test-id="addAddressAddress"
                                        fullWidth={true}
                                        onChange={(e) => this.updateAddAddressDetails('address', e.target.value)}
                                        placeholder="Enter Address"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    <Typography style={webStyle.card_input_message1}>City:</Typography>
                                    <OutlinedInput
                                        style={{ ...webStyle.inputRootForPayments, marginTop: '12px' }}
                                        className="inputBox"
                                        fullWidth={true}
                                        data-test-id="addAddressCity"
                                        onChange={(e) => this.updateAddAddressDetails('city', e.target.value)}
                                        placeholder="Enter City"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    <Typography style={webStyle.card_input_message1}>Zip Code:</Typography>
                                    <OutlinedInput
                                        className="inputBox"
                                        style={{ ...webStyle.inputRootForPayments, marginTop: '12px' }}
                                        value={this.state.addAddressFormDetails.zip_code}
                                        fullWidth={true}
                                        data-test-id="addAddressZipCode"
                                        placeholder="Max 9 digits"
                                        onChange={(e) => this.updateAddAddressDetails('zip_code', e.target.value)}
                                    />
                                    {
                                        this.state.addressZipCodeInputFieldError && <p style={{
                                            color: '#f22a2a',
                                            fontSize: '12px',
                                            margin: 0,
                                            fontWeight: 500,
                                            font: 'Poppins',
                                        }}>Please input atleast 4 digits </p>
                                    }
                                </Grid>
                                <Grid item xs={12} >
                                    <Typography style={webStyle.card_input_message1}>Select Country:</Typography>
                                    <Box style={{ marginTop: '12px' }}>
                                        {(this.selectMenuForEditPaymentScreen(cashCustomStyles))}
                                    </Box>
                                </Grid>
                                <Grid item xs={12} >
                                    <Typography style={{ ...webStyle.confirmBtnMessage1, cursor: 'pointer', float: 'right' }} data-test-id="addConfirmDetails" onClick={() => this.setNewAddress()}>Confirm Address</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </>
                }
                <Box>

                    {
                        this.state.paymentMethod == 'edit_address' &&
                        <>
                            <Box>
                                <Box style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography style={webStyle.formHeader1}>Edit Address</Typography>
                                </Box>
                            </Box>
                            <Box  className="custom_address_form1">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} >
                                        <Typography style={webStyle.card_input_message1}>Name:</Typography>
                                        <OutlinedInput
                                            style={{ ...webStyle.inputRootForPayments, marginTop: '12px' }}
                                            data-test-id="editAddressName"
                                            className="inputBox"
                                            fullWidth={true}
                                            onChange={(e) => this.updateAddAddressDetails('name', e.target.value)}
                                            defaultValue={this.state.addAddressFormDetails.name}
                                            placeholder="Enter Name"
                                        />
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Typography style={webStyle.card_input_message1}>Address:</Typography>
                                        <OutlinedInput
                                            style={{ ...webStyle.inputRootForPayments, marginTop: '12px' }}
                                            className="inputBox"
                                            data-test-id="editAddressAddress"
                                            fullWidth={true}
                                            onChange={(e) => this.updateAddAddressDetails('address', e.target.value)}
                                            defaultValue={this.state.addAddressFormDetails.address}
                                            placeholder="Enter Address"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} >
                                        <Typography style={webStyle.card_input_message1}>City:</Typography>
                                        <OutlinedInput
                                            style={{ ...webStyle.inputRootForPayments, marginTop: '12px' }}
                                            className="inputBox"
                                            fullWidth={true}
                                            data-test-id="editAddressCity"
                                            onChange={(e) => this.updateAddAddressDetails('city', e.target.value)}
                                            defaultValue={this.state.addAddressFormDetails.city}
                                            placeholder="Enter City"
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} >
                                        <Typography style={webStyle.card_input_message1}>Zip Code:</Typography>
                                        <OutlinedInput
                                            style={{ ...webStyle.inputRootForPayments, marginTop: '12px' }}
                                            value={this.state.addAddressFormDetails.zip_code}
                                            className="inputBox"
                                            data-test-id="editAddressZipCode"
                                            fullWidth={true}
                                            onChange={(e) => this.updateAddAddressDetails('zip_code', e.target.value)}
                                            placeholder="Max 9 digits"
                                        />
                                        {
                                        this.state.addressZipCodeInputFieldError && <p style={{
                                            margin: 0,
                                            color: '#f22a2a',
                                            font: 'Poppins',
                                            fontSize: '12px',
                                            fontWeight: 500
                                        }}>Please input atleast 4 digits </p>
                                    }
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Typography style={webStyle.card_input_message1}>Select Country:</Typography>
                                        <Box style={{ marginTop: '12px' }}>
                                            {(this.selectMenuForEditPaymentScreen(cashCustomStyles))}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} >
                                        <Typography style={{ ...webStyle.confirmBtnMessage1, cursor: 'pointer', float: 'right' }} data-test-id="editConfirmDetails" onClick={() => this.editAddress()}>Confirm Address</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </>
                    }
                </Box>
                {this.state.manageBtn &&
                    <Box>
                        {
                            this.state.paymentMethod == 'credit_debit_card' &&
                            <>
                                <Box>
                                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                                        <img src={card} style={{ width: '47px' }} />
                                        <Typography style={webStyle.formHeader1}>Credit/Debit Card</Typography>
                                    </Box>
                                    <Box style={webStyle.credit_card_form1}>

                                        <Grid container spacing={2} style={{ margin: '15px 10px 0px 10px' }}>
                                            <Grid item xs={12} sm={6}>
                                                <Typography style={webStyle.card_input_message1}>Card holder Name</Typography>
                                                <OutlinedInput
                                                    style={{ ...webStyle.inputRootForPayments, marginTop: '12px' }}
                                                    data-test-id="credit_card_name"
                                                    className="inputBox"
                                                    fullWidth={true}
                                                    value={this.state.paymentMethodAddDetails.cardName}
                                                    onChange={(e) => this.updateAddCardDetails('cardName', e.target.value)}
                                                    placeholder="Enter Card holder name"
                                                    inputProps={{ maxLength: 30 }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Typography style={webStyle.card_input_message1}>Expiry date</Typography>
                                                <OutlinedInput
                                                    onChange={(e) => this.updateAddCardDetails('expiry', e.target.value)}
                                                    data-test-id="credit_card_expiry"
                                                    className="inputBox"
                                                    fullWidth={true}
                                                    style={{ ...webStyle.inputRootForPayments, marginTop: '12px', width: '120px' }}
                                                    placeholder="12/22"
                                                    value={this.state.paymentMethodAddDetails.expiry}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} style={{ margin: '15px 10px 0px 10px' }}>
                                            <Grid item xs={12} sm={6}>
                                                <Typography style={webStyle.card_input_message1}>Card number</Typography>
                                                <OutlinedInput
                                                    style={{ ...webStyle.inputRootForPayments, marginTop: '12px' }}
                                                    data-test-id="credit_card_number"
                                                    className="inputBox"
                                                    fullWidth={true}
                                                    value={this.state.paymentMethodAddDetails.cardNumber}
                                                    onChange={(e) => this.updateAddCardDetails('cardNumber', e.target.value)}
                                                    placeholder="Enter card number"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <Typography style={webStyle.card_input_message1}>CVV</Typography>
                                                <OutlinedInput
                                                    className="inputBox"
                                                    data-test-id="credit_card_cvv"
                                                    style={{ ...webStyle.inputRootForPayments, marginTop: '12px', width: '120px' }}
                                                    value={this.state.paymentMethodAddDetails.cvv}
                                                    fullWidth={true}
                                                    placeholder="000"
                                                    onChange={(e) => this.updateAddCardDetails('cvv', e.target.value)}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={2} style={{ margin: '15px 10px 0px 10px' }}>
                                            <Grid item xs={12}>
                                                <Box>
                                                    <label className="container" style={{ paddingLeft: '25px' }} >
                                                        <input data-test-id="updateSaveCardSate" type="checkbox" checked={this.state.saveCardForFuture} onChange={() => this.updateSaveCardState()} />
                                                        <span className="checkmarkOne" style={{ borderColor: "white" }}></span>
                                                        <Typography style={webStyle.save_card_msg1}>Save this as your primary payment card </Typography>
                                                    </label>
                                                </Box>
                                            </Grid>

                                        </Grid>
                                    </Box>

                                </Box>
                            </>
                        }
                        {
                            this.state.paymentMethod.includes('dynamic_card') &&
                            <>
                                {this.state.address &&
                                    <Box style={{ marginTop: '60px', paddingRight: '50px', opacity: '0.6' }}>
                                        <Box className="addressEditBox" style={webStyle.editAddressBox1}>
                                            <Typography style={webStyle.customLabel12} id="BillingAddressLabel">Billing Address</Typography>
                                            {this.state.paymentMethod != 'edit_address' &&
                                                <Typography className="EditAddressBtn" style={webStyle.editDetailsFont1} data-test-id="editDetailsBtn"
                                                    onClick={() => this.editDetails()}>Edit details</Typography>
                                            }
                                        </Box>
                                        <Box id="EditAddressForm">
                                            <Typography className="NameFieldForEditAddress" style={this.state.paymentMethod == 'edit_address' ? webStyle.billingAddressFontActive : webStyle.billingAddressFont}>{this.state.address.attributes.name}</Typography>
                                            <Typography className="addressFieldForEditAddress" style={this.state.paymentMethod == 'edit_address' ? webStyle.billingAddressFontActive : webStyle.billingAddressFont}>{this.state.address.attributes.address}</Typography>
                                            <Typography className="ZipFieldForEditAddress" style={this.state.paymentMethod == 'edit_address' ? webStyle.billingAddressFontActive : webStyle.billingAddressFont}>{this.state.address.attributes.city + ", " + this.state.address.attributes.zipcode + ", " + this.state.address.attributes.country}</Typography>

                                        </Box>
                                    </Box>
                                }
                            </>
                        }
                    </Box>}
            </Box>

        )
    }

    manageBtnView() {
        return (
            !this.state.manageBtn ?
                <Typography style={webStyle.editDetailsFont1} data-test-id="manageBtn"
                    onClick={() => this.manageBtn("manage")}>Manage</Typography> :
                <Typography style={webStyle.confirmBtnMessage1} data-test-id="manageContinueBtn"
                    onClick={() => this.manageBtn("confirm")}>Confirm</Typography>

        )
    }
    paymentMethodListing() {
        return (
            this.state.paymentMethods &&
            this.state.paymentMethods.map((values: any) => {
                return (<>
                    <Box style={{ ...webStyle.outlineDashedBorders2, position: 'relative', paddingRight: '25px' }} data-test-id={`dynamic_card_${values.id}`} className={this.state.paymentMethod == `dynamic_card_${values.id}` ? 'activeBorder' : ""} onClick={() => this.paymentMethodForDynamicCard(values)}>
                        {values.attributes.primary_card &&
                            <div style={{ position: 'absolute', top: '-11px', right: '23px', background: '#814FF9', borderRadius: '5px', padding: '1px 10px', fontSize: '12px', color: '#fff', }}>Primary</div>
                        }
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>

                            <div className="main-radio-box" style={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                                <div className="radio-box2" >
                                    <input type="radio" className="radio-button" value="search_engine" checked={this.state.paymentMethod === `dynamic_card_${values.id}`} />
                                    <label>
                                        <Box style={{ display: 'flex', alignItems: 'center' }}>
                                            <img src={card} style={{ width: '47px' }} />
                                            <Box>
                                                <Typography style={webStyle.cardNameLabel1}>{values.attributes.card_name + ' ' + values.attributes.card_number}</Typography>
                                                <Typography style={webStyle.cardExpireLabel1}>{`expires on ${values.attributes.expires_on}`}</Typography>
                                            </Box>
                                        </Box>
                                    </label>
                                </div>

                            </div>
                            {this.state.manageBtn &&
                                <div style={{ cursor: 'pointer' }} data-test-id={`deleteCard-${values.id}`} onClick={() => this.deleteCard(values)}> <img src={deleteIcon} /></div>
                            }
                        </div>
                        {(this.state.manageBtn && this.state.paymentMethod == `dynamic_card_${values.id}`) &&
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
                                <div style={{ marginBottom: '10px', marginLeft: '10px' }}>
                                    <Typography style={webStyle.verifyConfirmationLabel}>Enter CVV to verify</Typography>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <OutlinedInput
                                            type="number"
                                            value={this.state.verifyCvvValue}
                                            className="inputBox"
                                            data-test-id="verify_cvv_field"
                                            style={{ ...webStyle.inputRootForPayments, width: '120px' }}
                                            fullWidth={true}
                                            placeholder="000"
                                            onChange={(e) => this.cvvVerify(e.target.value)}
                                        />
                                        {this.state.cvvVerifyStatus === "verified" ? <Typography style={{ ...webStyle.verifyConfirmationBtn, display: 'flex', alignItems: 'center' }}>Verified{" "}
                                            <img src={checckedIcon} alt="select" style={{ height: '15px', width: '15px' }} />
                                        </Typography> :
                                            <Typography style={webStyle.verifyConfirmationBtn} data-test-id="VerifyButton" onClick={() => this.verifyAPICalling(values)}>Verify</Typography>}

                                    </div>
                                </div>
                            </div>
                        }
                    </Box>
                    {(this.state.manageBtn && this.state.paymentMethod == `dynamic_card_${values.id}`) &&
                        <Box style={{ marginTop: '10px' }} >
                            <label className="container" style={{ paddingLeft: '25px' }} >
                                <input type="checkbox" checked data-test-id="updateSaveCardSate" />
                                <span className="checkmarkOne" style={{ borderColor: "white" }}></span>
                                <Typography style={webStyle.save_card_msg1}>Save this as your primary payment card </Typography>
                            </label>
                        </Box>}
                </>

                )
            })

        )
    }

    showRenderedAddress() {
        return (
            <Box style={{ marginTop: '60px', paddingRight: '50px' }}>
                <Box id="editBoxForAddress" style={webStyle.editAddressBox1}>
                    <Typography className="LabelForEditBox" style={webStyle.customLabel12}>Billing Address</Typography>
                    {this.state.paymentMethod != 'edit_address' &&
                        <Typography className="BtnSection" style={webStyle.editDetailsFont1} data-test-id="editDetailsBtn"
                            onClick={() => this.editDetails()}>Edit details</Typography>
                    }
                </Box>
                <Box className="BillingAddressSectionForEdit">
                    <Typography style={this.state.paymentMethod == 'edit_address' ? webStyle.billingAddressFontActive : webStyle.billingAddressFont} id="EditAddressFieldForName">{this.state.address?.attributes?.name}</Typography>
                    <Typography style={this.state.paymentMethod == 'edit_address' ? webStyle.billingAddressFontActive : webStyle.billingAddressFont} id="EditAddressFieldForaddress">{this.state.address?.attributes?.address}</Typography>
                    <Typography style={this.state.paymentMethod == 'edit_address' ? webStyle.billingAddressFontActive : webStyle.billingAddressFont} id="EditAddressFieldForZip">{this.state.address?.attributes?.city + ", " + this.state.address?.attributes?.zipcode + ", " + this.state.address?.attributes?.country}</Typography>

                </Box>
            </Box>
        )
    }

    render() {
        return (
            // Customizable Area Start
            // Required for all blocks 
            <BackgroundBoxSatyle style={{ padding: '4% 3%' }}>

                <Box>
                    <Box style={webStyle.boxSettings}>
                        <a style={webStyle.SettingsName}>Manage Subscription</a>
                        <Link to={'/Dashboard'} className="lng-close-rounded">
                            <img style={webStyle.group_Exit} src={group_Exit} />
                        </Link>
                    </Box>
                    <Box style={webStyle.SubscriptionName}>Edit Details</Box>
                    <Box style={{ display: 'flex', justifyContent: 'center', paddingBottom: '35px', paddingTop: '32px' }}>
                        <Box className="borderCss1" style={{ width: '1200px' }}>
                            <Box className="verify-box-one1">
                                <ThemeProvider theme={theme}>
                                    <CssBaseline />


                                    <Container>
                                        <Box dir={this.state.languagesLogin} style={{ display: 'flex', alignItems: 'center' }}>
                                            <NavLink to="/ManageSubscriptionController"><img src={backIcon} alt="back-icon" /></NavLink>
                                            <div style={webStyle.customLabel1}><p>Payment Details</p></div>
                                        </Box>
                                    </Container>

                                    <Box style={{
                                        padding: '20px'
                                    }}>
                                        {this.currenPlanDetails()}
                                        <Grid container spacing={2} style={{ marginTop: '20px' }} dir={this.state.languagesLogin}>
                                            <Grid item xs={12} sm={6}>
                                                <Box className="method_container">
                                                    <div style={{ display: 'flex', justifyContent: ' space-between', alignItems: "center", }}>
                                                        <Typography style={webStyle.customLabel12}>Method</Typography>
                                                        {this.manageBtnView()}
                                                    </div>
                                                    {
                                                        this.state.deleteAlertMessage &&
                                                        <Box style={{ display: 'flex', alignItems: 'center', width: '85%', marginTop: '10px' }}>
                                                            <img src={info} />&nbsp;&nbsp;
                                                            <Box>
                                                                <Typography style={{ color: 'white', fontFamily: "Poppins" }}>Deleting the Primary Card will make the next card
                                                                    as primary.</Typography>
                                                            </Box>
                                                        </Box>
                                                    }

                                                    {this.paymentMethodListing()}
                                                    <Box style={{ ...webStyle.outlineDashedBorders, display: 'flex', alignItems: 'center' }} data-test-id="credit-debit-card-btn" className={this.state.paymentMethod == 'credit_debit_card' ? 'activeBorder' : ""} onClick={() => this.checkboxHandle('credit_debit_card')}>
                                                        <div className="main-radio-box">
                                                            <div className="radio-box2" data-test-id="radioBox1"><input type="radio" className="radio-button" value="search_engine" checked={this.state.paymentMethod === "credit_debit_card"} /><label>
                                                                <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <img src={card} style={{ width: '47px' }} />
                                                                    <Typography style={webStyle.customLabel17}>Credit/Debit Card</Typography>
                                                                </Box>
                                                            </label></div>
                                                        </div>
                                                    </Box>
                                                </Box>
                                                {this.state.deleteErrorMsg && <Typography style={{ color: 'red', marginTop: "5px", fontFamily: "Poppins" }}>Cannot delete, need atleast one card as primary</Typography>}
                                                {
                                                    (this.state.paymentMethod.includes('dynamic_card') && this.state.manageBtn) ? null : this.state.address.length !== 0 && this.showRenderedAddress()
                                                }
                                                {this.state.address.length == 0 &&
                                                    <Box style={{ marginTop: '60px', paddingRight: '50px' }}>
                                                        <Box id="editBoxForAddress" style={{ ...webStyle.addAddressBox, flexDirection: 'column' }}>
                                                            <Typography className="LabelForEditBox" style={webStyle.customLabel12}>Billing Address</Typography>
                                                            <Box data-test-id="addAddressBtn" onClick={() => { this.checkboxHandle('add_address') }} style={{ ...webStyle.addressBtn, display: 'flex', alignItems: 'center' }}>
                                                                <img src={plus} style={{ width: '16px', height: '16px', marginLeft: '10px' }} />
                                                                <Typography style={webStyle.customLabel6}>Add new address</Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                }

                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                {this.state.paymentMethod === "apple_pay" && <Box style={{ color: "#508a8f", textAlign: "center", fontSize: "16px", paddingTop: "50px" }} dir={this.state.languagesLogin}>
                                                    <div><p>Chose a different mode of payment for your future subscription. <br />   You can click "<span style={{ color: '#814FF9' }}>Manage</span>" to add or remove payment methods. </p></div>
                                                    <div><p>  You can click "<span style={{ color: '#814FF9' }}>Edit details</span>" to change your billing address</p></div>
                                                </Box>}
                                                {this.formRender1()}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </ThemeProvider>
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <Dialog
                            open={this.state.openDeleteSuccess}
                            keepMounted
                            data-test-id="dialogModel"
                            onClose={this.deleteCardModel}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                            PaperProps={{ style: this.dialogCashPaperStyle }}
                        >
                            <DialogTitle id="alert-dialog-slide-title" style={{ textAlign: "center" }}><img src={SuccessCard} /></DialogTitle>
                            <DialogContent style={{ padding: '8px 20px' }}>
                                <DialogContentText id="alert-dialog-slide-description" className='dialogContent'>
                                    Your card has been deleted.
                                </DialogContentText>
                            </DialogContent>
                        </Dialog>

                    </Box>
                </Box>
                <Box className="AddressUpdatedPopup">
                    <Dialog
                        className="dialogMainBody"
                        data-test-id="dialogModel"
                        open={this.state.openSuccessAddress}
                        onClose={this.dialogCashModel}
                        keepMounted
                        aria-describedby="alert-dialog-slide-description"
                        aria-labelledby="alert-dialog-slide-title"
                        PaperProps={{ style: this.dialogCashPaperStyle }}
                    >
                        <DialogTitle className="dialogTitleMain" id="alert-dialog-slide-title" style={{ textAlign: "center" }}><img src={SuccessCard} /></DialogTitle>
                        <DialogContent id="dialogContent" style={{ padding: '8px 20px' }}>
                            <DialogContentText id="alert-dialog-slide-description" data-test-id="contentText" className='dialogContent'>
                                Billing Address updated successfully!
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        id="rejectedPopup"
                        open={this.state.openRejectedPopup}
                        keepMounted
                        data-test-id="dialogModel"
                        onClose={this.dialogRejectModel}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                        PaperProps={{ style: this.dialogRejectedCashPaperStyle }}
                    >
                        <DialogTitle id="alert-dialog-slide-title" className="dialogTitleOfRejectModel" style={{ textAlign: "center" }}><img src={close} /></DialogTitle>
                        <DialogContent className="contentOfRejectPopup" style={{ padding: '8px 20px' }}>
                            <DialogContentText id="alert-dialog-slide-description" data-test-id="PaymentFailedPopup" className='dialogContent' style={{ textAlign: 'center' }}>
                                Payment Failed,
                                try a different payment method
                                or try again later
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                </Box>
            </BackgroundBoxSatyle>

            // Customizable Area End
        );
    }
}

// Customizable Area Start
const BackgroundBoxSatyle = styled(Box)({ width: '100%', background: '#2a2a39', height: '100vh', overflowY: 'scroll' })

const webStyle = {
    addressBtn: {
        borderRadius: "12px",
        border: '1px solid #928F98',
        width: '80%',
        height: '50px',
        marginTop: '22px',
        cursor: 'pointer',
    },
    customLabel6: {
        color: 'white',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: 600,
        fontFamily: 'Poppins',
        margin: '12px 12px',
    },
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: "red !important",
        }
    },
    save_card_msg1: {
        color: 'white',
        fontStyle: 'normal',
        fontSize: '12px',
        fontWeight: 400,
        fontFamily: 'Poppins',
    },
    card_input_message1: {
        color: 'white',
        fontStyle: 'normal',
        fontSize: '14px',
        fontWeight: 300,
        fontFamily: 'Poppins',
    },
    credit_card_form1: {
        borderRadius: '12px',
        border: '1px solid #2DE2D5',
        boxShadow: '0px 0px 10px 0px rgba(44, 226, 213, 0.66)',
        width: '504px',
        marginTop: '22px',
    },
    boxSettings: { display: 'flex', justifyContent: 'space-between' },
    group_Exit: { width: '36px', height: '36px' },
    SettingsName: { color: "#FFF", fontSize: "14px", fontWeight: 600 },
    SubscriptionName: { color: "#FFF", fontSize: "28px", fontWeight: 600, marginTop: '7px' },
    billingAddressFontActive: {
        color: '#2CE2D5',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: 500,
        fontFamily: 'Poppins',
    },
    billingAddressFont: {
        color: '#928F98',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: 500,
        fontFamily: 'Poppins',
    },
    address_form1: {
        width: '80%',
    },
    formHeader2: {
        color: '#928F98',
        fontStyle: 'normal',
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: 'Poppins',
        margin: '12px 7px',
    },
    customLabel1: {
        color: 'white',
        fontStyle: 'normal',
        fontSize: '24px',
        fontWeight: 600,
        fontFamily: 'Poppins',
        marginLeft: "10px",
        marginRight: "10px",
    },
    cardNameLabel1: {
        color: 'white',
        fontStyle: 'normal',
        fontSize: '14px',
        fontWeight: 600,
        fontFamily: 'Poppins',
        margin: '0px 7px',

    },
    cardExpireLabel1: {
        color: 'rgba(255, 255, 255, 0.50)',
        fontStyle: 'normal',
        fontSize: '10px',
        fontWeight: 400,
        fontFamily: 'Poppins',
        margin: '0px 7px',
    },

    confirmBtnMessage1: {
        color: '#C9F9F6',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: 600,
        fontFamily: 'Poppins',
    },
    confirmBtnMessage: {
        color: '#C9F9F6',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: 600,
        fontFamily: 'Poppins',
    },
    customLabel12: {
        color: 'white',
        fontStyle: 'normal',
        fontSize: '20px',
        fontWeight: 600,
        fontFamily: 'Poppins',
    },
    editAddressBox1: {
        display: 'flex',
        justifyContent: ' space-between',
        alignItems: "center",
    },
    addAddressBox: {
        display: 'flex',
        justifyContent: ' space-between',
        alignItems: "flex-start",
    },
    editDetailsFont1: {
        color: '#814FF9',
        fontStyle: 'normal',
        fontSize: '14px',
        fontWeight: 500,
        fontFamily: 'Poppins',
        cursor: 'pointer',
    },
    customLabel13: {
        color: '#928F98',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: 500,
        fontFamily: 'Poppins',
    },
    customLabel14: {
        color: 'white',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: 500,
        fontFamily: 'Poppins',
    },
    customLabel15: {
        color: '#2CE2D5',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: 600,
        fontFamily: 'Poppins',
    },
    customLabel16: {
        color: 'white',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: 600,
        fontFamily: 'Poppins',
        margin: '12px 12px',
    },
    customLabel17: {
        color: 'white',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: 600,
        fontFamily: 'Poppins',
        margin: '12px 7px',
    },
    verifyConfirmationLabel: {
        color: 'white',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: 400,
        fontFamily: 'Poppins',
        margin: '12px 7px',
    },
    verifyConfirmationBtn: {
        color: '#2df1e3',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: 500,
        cursor: 'pointer',
        fontFamily: 'Poppins',
        margin: '12px 7px',
    },
    formHeader1: {
        fontSize: '20px',
        color: 'white',
        fontStyle: 'normal',
        fontFamily: 'Poppins',
        margin: '12px 7px',
        fontWeight: 600,
    },
    inputRootForPayments: {
        height: '56px',
        borderRadius: '10px',
        fontFamily: 'Poppins',

        '& .MuiOutlinedInput-notchedOutline .MuiOutlinedInput-input': {
            padding: '14.5px 14px',
        },
        '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: '#f44336 !important',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#2DF1E3 !important',
        },

        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#2DF1E3 !important',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#2DF1E3 !important',
        },
        '& input::placeholder': {
            fontSize: '12px',
            color: 'rgba(255, 255, 255, 0.60)',
            fontWeight: 500,

        }, '& .MuiInputBase-input': {
            borderColor: '#2DF1E3 !important'
        },
    },
    cekbox: {
        '& .MuiCheckbox-root': {
            color: 'aqua',
        },
        '& .MuiIconButton-root': {
            padding: '0px'
        }
    },

    buttons: {
        margin: '0px',
        fontWeight: 700,
        height: "50px",
        borderRadius: '10px',
        Color: '#3C3C50',
        fontSize: '20px',
        backgroundColor: "#c7f9f6",
        textTransform: 'none',
    },
    addressBtn1: {
        border: '1px solid #928F98',
        borderRadius: "12px",
        width: '80%',
        height: '50px',
        marginTop: '22px',
        cursor: 'pointer',
    },
    addressBtn2: {
        borderRadius: "12px",
        border: '1px solid #928F98',
        width: '80%',
        height: '50px',
        marginTop: '15px',
        cursor: 'pointer',
    },
    outlineDashedBorders: {
        borderRadius: "12px",
        border: '1px solid #928F98',
        borderStyle: 'dashed',
        borderWidth: '2px',
        width: '100%',
        height: '50px',
        marginTop: '15px',
        cursor: 'pointer',
    },
    outlineDashedBorders2: {
        borderRadius: "12px",
        border: '1px solid #928F98',
        borderStyle: 'dashed',
        borderWidth: '2px',
        width: '100%',
        marginTop: '15px',
        cursor: 'pointer',
    },
    logoandimg1: {
        display: 'flex',
        alignItems: 'center',
        color: "white",
        gap: 20,
        justifyContent: 'right'
    },

};
// Customizable Area End
// Customizable Area End
