import React from "react";
// Customizable Area Start
import "./style.css";
import {
    settingicon,
    subarrowicon,
    stockPortfolio,
    stockTicker
} from "./assets";
import {
    Box,
    Typography,
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { backIcon } from "../../forgot-password/src/assets";
import Grid from '@material-ui/core/Grid';

// Customizable Area End
import DashboardController, { Props } from "./DashboardController";
export default class Dashboard extends DashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area Start



    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <div className="pagedashboard-wrapper">
                <div className="header-wrapper">
                    <div className="page-title">
                        <h4>
                            <NavLink to="/CategoriessubcategoriesWeb"><img src={backIcon} alt="back-icon" /></NavLink>
                            &nbsp;Add Stock
                        </h4>
                    </div>
                    <div id="pageInfoStocKTicker" className="page-info">
                        <ul data-test-id="StockCurrencyDropDown" className="drop-menu">
                            <li className="dropdownitems">
                                <div
                                    data-test-id="currencyDropdoen"
                                    id="currencyStockDropdown"
                                    onClick={() => this.handleDropdown()}
                                    className={this.state.dropdownVisiblity}
                                >
                                    <p className="arrowIconForStock">
                                        {this.state.selectedCurrency} <img src={subarrowicon} alt="subarrow" />
                                    </p>
                                    <ul id="labelForStock">
                                        {this.state.currencyList && this.state.currencyList.list.map((value: any, index: number) => {
                                            return <li data-test-id={"currency-" + index} onClick={() => this.selectCurrency(value)}>{value.symbol + value.currency_type}</li>
                                        })}
                                    </ul>
                                </div>
                            </li>
                            <li className="settingIcon">
                                <NavLink to="/Account-Detail">
                                    <img date-test-id="settingIcon" src={settingicon} alt="setting" />
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>

                <Box className="stock-select-boxs">
                    <Box
                        onClick={() => this.setState({ stockBtn: 'portfolio' })}
                        data-test-id="portfolioCard"
                        className={`stock-card ${this.state.stockBtn == 'portfolio' ? 'stock-card-active' : ''}`}
                    >
                        <Box>
                            <img src={stockPortfolio} style={{ width: '50px', margin: 'auto' }} />
                        </Box>
                        <Typography style={webStyle.stockPortfolioLabel}>
                            Stock Wallets & Exchanges
                        </Typography>
                    </Box>
                    <Box
                        onClick={() => this.setState({ stockBtn: 'ticker' })}
                        data-test-id="tickerCard"
                        className={`stock-card ${this.state.stockBtn == 'ticker' ? 'stock-card-active' : ''}`}
                    >
                        <Box>
                            <img src={stockTicker} style={{ width: '50px', margin: 'auto' }} />
                        </Box>
                        <Typography style={webStyle.stockTickerLabel}>
                            Stock Positions
                        </Typography>
                    </Box>
                </Box>
               
                <Box>
                    <Box style={{ ...webStyle.btnBox, position: 'absolute', bottom: '20px', right: '30px' }} data-test-id="nextBtn" onClick={() => this.redirectToNext()}>
                        <Typography style={webStyle.btnLabel}>
                            Next
                        </Typography>
                    </Box>
                </Box>
            </div>
        );
        // Customizable Area End
    }
}

// Customizable Area Start

type IStyle = { [key: string]: React.CSSProperties }

const webStyle: IStyle = {
    stockPortfolioLabel: { color: '#FFF', fontFamily: 'Poppins', fontSize: '15px', fontWeight: 500, marginTop: '10px', textAlign: "center" },
    stockTickerLabel: { color: '#3C3C50', fontFamily: 'Poppins', fontSize: '15px', fontWeight: 500, marginTop: '24px', textAlign: "center" },
    btnLabel: {
        color: '#3C3C50', fontFamily: 'Poppins', fontSize: '15px', fontWeight: 700, padding: '5px',
        borderRadius: '50% !important'
    },
    btnBox: { cursor: 'pointer', backgroundColor: '#C9F9F6', width: '135px', borderRadius: '3px', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '5px' },
}
// Customizable Area End
