import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import storage from "framework/src/StorageProvider";
import React from "react";
import { FieldArrayRenderProps, FormikValues } from "formik";
import * as Yup from "yup";
import { DayValue } from "react-modern-calendar-datepicker";
import moment from "moment";
import toast from "react-hot-toast";

export interface ResponseJson {
    selection: string,
    availability: SlotsResponse[],
    data: SingleDayDODataObject[],
    message: string,
}

export interface ApiCallData {
    contentType?: string,
    method: string,
    endPoint: string,
    body?: Object,
    type?: string
}

interface ITimeSlots { 
    startTime: Date | null; 
    endTime: Date | null 
}

interface IWeekDay {
    selected: boolean;
    timeSlot: ITimeSlots[];
  }
  
  interface FormValues {
    Sunday: IWeekDay;
    Monday: IWeekDay;
    Tuesday: IWeekDay;
    Wednesday: IWeekDay;
    Thursday: IWeekDay;
    Friday: IWeekDay;
    Saturday: IWeekDay;
  }

export interface AvailabilityDays {
    value: string,
    label: string
}

export interface SlotsResponse {
    day: string;
    availability: DaysAvailability[] | string;
    id: string | number | undefined | null;
}
export interface DaysAvailability {
    id?: number | string,
    weekly_availability_id?: number,
    end_time: string,
    start_time: string,
    start_meridian: string,
    end_meridian: string,
    availability: boolean,
    created_at?: string,
    updated_at?: string,
}

export interface TimeSlot {
    start_time: string;
    start_meridian: string;
    end_time: string;
    end_meridian: string;
    availability: boolean;
}

export interface AbNewData {
    [key: number]: TimeSlot[];
}

export interface AbNew {
    data: AbNewData;
}

export interface FilteredData {
    day: string;
    availability: DaysAvailability[];
}

interface SingleDayDODataObject {
    id: string,
    type: string,
    attributes: {
        ends_on: string,
        unavailable: boolean,
        end_meridian: string,
        end_time: string,
        start_meridian: string,
        start_time: string,
        title: string,
        repeat: keyof Repeat,
        repeat_days: string[]
        event_date: string,
        does_not_repeat: keyof DoesNotRepeat,
    }
} 
interface DOCustomFormDataObject {
    CustomRepeatEvery: number,
    CustomRepeatOn: string[],
    CustomOptions: string,
}

interface EditDODataObject {
    event_date: Date,
    title: string,
    unavailable: boolean,
    startHours: string | null,
    startMinutes: string | null,
    startMeridian: string | null,
    endHours: string | null,
    endMinutes: string | null,
    endMeridian: string | null,
    does_not_repeat: keyof DoesNotRepeat,
    repeat: keyof Repeat,
    repeat_days: string[],
    ends_on: string | null,
    options: string
}
interface DOInitialValueObject {
    title: string,
    unavailable: boolean,
    startTimeHours: Date | null,
    startTimeMinutes: Date | null,
    startTimeMeridian: { label: string, value: string},
    endTimeHours: Date | null,
    endTimeMinutes: Date | null,
    endTimeMeridian: { label: string, value: string},
    options: { label: string, value: number},
    endTimeValidation?: string,
}

interface DoesNotRepeat {
    Does_not_repeat: number
    Daily: number
    Custom: number
}

interface Repeat {
    Weekly: number
    Monthly : number
    Yearly: number
}

interface DOCustomInitialValueObject {
    endDate: Date | null,
    options: string,
    repeatEvery: { label: string, value: number },
    occurence: number | null
}

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    isEditable: boolean;
    slotsDataResponse: SlotsResponse[];
    anchorElOnCustom: HTMLAnchorElement | null;
    anchorElOnDO: HTMLAnchorElement | null;
    copyTimesTo: string[],
    selectedDayForCopy: keyof FormValues | null,
    slotsSuccessModal: boolean,
    slotsCancelModal: boolean,
    selectedRepeatOn: string[],
    plannerInfo: {
        name:string,
        email:string,
        image:string
      },
    addDateOverridesModalOpen: boolean,
    addCustomOptionsDOModelOpen: boolean,
    selectedOverrideDay: DayValue,
    selectedOverrideDayDialog: DayValue,
    SingleDayEventData: SingleDayDODataObject[],
    AllDayEventData: SingleDayDODataObject[],
    DOCustomFormData: DOCustomFormDataObject | null,
    editDOFormId: string | null,
    editDOFormData: EditDODataObject | null,
    CustomDOInitialValue: DOCustomInitialValueObject,
    DOInitialValue: DOInitialValueObject,
    deleteConfirm: boolean,
    openSnackbar: boolean,
    errorMsg: string,
    // Customizable Area End
}
interface SS {
    id: any;
}

export default class DashboardController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getSlotsDataAPICallId: string = "";
    postSlotsDataAPICallId: string = "";
    getDayEventDataAPICallId: string = "";
    getAllEventDataAPICallId: string = "";
    postDODataAPICallId: string = "";
    putDODataAPICallId: string = "";
    deleteEventAPICallId: string = "";
    getUserProfileAPICallId: string="";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            isEditable: false,  
            anchorElOnCustom: null,
            anchorElOnDO: null,
            selectedDayForCopy: null,
            slotsDataResponse: [],
            copyTimesTo: [],
            slotsSuccessModal: false,
            slotsCancelModal: false,
            selectedRepeatOn: [],
            addDateOverridesModalOpen: false,
            addCustomOptionsDOModelOpen: false,
            selectedOverrideDay: {
                day: new Date().getDate(), 
                month: new Date().getMonth() + 1, 
                year: new Date().getFullYear()
            },
            selectedOverrideDayDialog: {
                day: new Date().getDate(), 
                month: new Date().getMonth() + 1, 
                year: new Date().getFullYear()
            },
            SingleDayEventData: [],
            AllDayEventData: [],
            DOCustomFormData: null,
            editDOFormId: null,
            editDOFormData: null,
            DOInitialValue: this.DefaultDOInitialValue,
            CustomDOInitialValue: this.DefaultCustomDOInitialValue,
            deleteConfirm: false,
            openSnackbar: false,
            errorMsg: "",
            plannerInfo:{
                name:"",
                image:"",
                email:""
              },
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            // For Web

            if (responseJson && !responseJson.errors) {
                this.successCallBack(responseJson, apiRequestCallId);
                this.calendarSuccessCallBack(responseJson, apiRequestCallId);
            }
            if (responseJson.errors && apiRequestCallId === this.postSlotsDataAPICallId) {
                this.setState({
                    errorMsg: "Cannot be saved.Edit plans first to set availibility hours",
                    openSnackbar: true,
                })
            }
            if(apiRequestCallId === this.getUserProfileAPICallId){
                this.setState({
                  plannerInfo: responseJson.data.attributes
                })
              }
        }
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        this.getSlotsData();
        this.GetAllEvents();
        this.GetDayEvents();
        this.getUserProfile()
        // Customizable Area End
    }

    // Customizable Area Start

    

    getSlotsData = async () => {
        this.getSlotsDataAPICallId = await this.apiCall({
            contentType: "application/json",
            method: 'GET',
            endPoint: `bx_block_formapprovalworkflow/wealth_planners/get_schedule_time`,
            body: "",
            type: ""
        });
    }

    successCallBack = (responseJson2: ResponseJson, apiRequestCallId: string) => {
        if (responseJson2 && apiRequestCallId === this.getSlotsDataAPICallId) {
            this.setState({
                slotsDataResponse: responseJson2?.availability || []
            })
        }
        if (responseJson2 && apiRequestCallId === this.postSlotsDataAPICallId) {
            this.getSlotsData();
            if (this.state.copyTimesTo.length === 0) {
                this.setState({
                    slotsSuccessModal: true
                })
            }
        }
    }

    displayTimeConverter = (startsAtUTC: string, endsAtUTC: string) => {
        const startsAtDate = new Date(`1970-01-01T${startsAtUTC}:00Z`);
        const endsAtDate = new Date(`1970-01-01T${endsAtUTC}:00Z`);
    
        const formattedStartTime = moment(startsAtDate).format("hh:mm A");
        const formattedEndTime = moment(endsAtDate).format("hh:mm A");
        return `${formattedStartTime} - ${formattedEndTime}`
      }

    calendarSuccessCallBack = (responseJson2: ResponseJson, apiRequestCallId: string) => {
        if (responseJson2 && apiRequestCallId === this.getDayEventDataAPICallId) {
            this.setState({
                SingleDayEventData: responseJson2.data 
            })
        }
        if (responseJson2 && apiRequestCallId === this.getAllEventDataAPICallId) {
            this.setState({
                AllDayEventData: responseJson2.data
            })
        }
        if (responseJson2 && apiRequestCallId === this.postDODataAPICallId) {
            this.handleAddOverridesModalClose();
            this.GetAllEvents();
            this.GetDayEvents(this.state.selectedOverrideDay);
        }
        if (responseJson2 && apiRequestCallId === this.putDODataAPICallId) {
            this.handleAddOverridesModalClose();
            this.GetAllEvents();
            this.GetDayEvents(this.state.selectedOverrideDay);
        }
        if (responseJson2 && apiRequestCallId === this.deleteEventAPICallId) {
            this.GetAllEvents();
            this.GetDayEvents(this.state.selectedOverrideDay);
            this.setState({
                deleteConfirm: false
            })
        }
    }

    navigateRoute = (route: string) => {
        const msg = new Message(getName(MessageEnum.NavigationMessage));
        msg.addData(getName(MessageEnum.NavigationTargetMessage), route);
        msg.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(msg)
    }
    getUserProfile = async () => {
        this.getUserProfileAPICallId = await this.apiCall({
          method: "GET" + '',
          endPoint: "bx_block_formapprovalworkflow/wealth_planners/show_profile",
          body: Object,
          contentType:"application/json"
        });
      }
  goToProfilePage = async () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "PlannerProfile");
    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message)
  }

    handleCopyPopoverOpen = (event: React.MouseEvent<HTMLElement>, currentDay: keyof FormValues) => {
        this.setState({
            anchorElOnCustom: event.currentTarget as HTMLAnchorElement,
            copyTimesTo: [currentDay],
            selectedDayForCopy: currentDay
        })
    };

    handleCopyPopoverClose = () => {
        this.setState({
            anchorElOnCustom: null,
            copyTimesTo: [],
            selectedDayForCopy: null
        })
    };

    handleCloseSnackbar = () => {
        this.setState({
          openSnackbar: false
        })
    }

    handleOptionPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({
            anchorElOnDO: event.currentTarget as HTMLAnchorElement,
        })
    };

    handleOptionPopoverClose = () => {
        this.setState({
            anchorElOnDO: null,
        })
    };

    handleCopySlot = (
        checked: boolean, 
        selectedDay: string, 
        values: FormValues, 
    ) => {
        if (checked && this.state.selectedDayForCopy) {
            const selectArray = [...this.state.copyTimesTo, selectedDay]
            const newValues = {...values, [selectedDay]: values[this.state.selectedDayForCopy] }
            this.handleSlotsSubmit(newValues);
            this.setState({
                copyTimesTo: selectArray
            })
        } else if (selectedDay !== this.state.selectedDayForCopy){
            this.setState({
                copyTimesTo: this.state.copyTimesTo.filter(dayTo => dayTo !== selectedDay)
            })
        }
    }

    handleOptionsChange = (
        checked: boolean, 
        options: { label: string, value: number }, 
        values: FormikValues, 
        setFieldValue: (field: string, value: { label: string, value:number }) => void
    ) => {
        if (checked) {
            if (options.value === 2) {
                this.handleAddOverridesCustomOptionModalOpen()
            }
            this.setState({
                anchorElOnDO: null
            })
            setFieldValue('options', options)
        }
    }

    handleEdit = () => {
        this.setState({ isEditable: true });
    }

    cancelEditSlotsForm = () => {
        this.setState({
            slotsCancelModal: true
        })
    }

    cancelCancelEditSlots = () => {
        this.setState({
            slotsCancelModal: false
        })
    }

    cancelEditSlots = (resetForm: () => void) => {
        resetForm();
        this.setState({ 
            isEditable: false,
            slotsCancelModal: false
         });
    }

    weekDays: (keyof FormValues)[] = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
    ];

    DateOverridesOptions = [
        {label: "Does not repeat", value: 0},
        {label: "Daily", value: 1}, 
        {label: "Custom", value: 2},
    ]

    DateOverridesCustomOptions = [
        {label: "Weekly", value: 0}, 
        {label: "Monthly", value: 1},
        {label: "Yearly", value: 2}
    ]

    DefaultDOInitialValue = {
        title: "",
        unavailable: false,
        startTimeHours: null,
        startTimeMinutes:null,
        startTimeMeridian: { label: "a.m.", value: "a.m." },
        endTimeHours: null,
        endTimeMinutes: null,
        endTimeMeridian: { label: "a.m.", value: "a.m." },
        options: this.DateOverridesOptions[0],
        endTimeValidation: ""
    }

    DefaultCustomDOInitialValue = {
        endDate: null,
        options: 'never',
        repeatEvery: this.DateOverridesCustomOptions[0],
        occurence: null
    }

    formateTime = (dateString: string) => {
        const newDate = new Date(dateString);
        const options: Intl.DateTimeFormatOptions = {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
            timeZone: "UTC",
        };
        return newDate.toLocaleTimeString("en-US", options);
        
    };

    formateSlotTime = (timeString: string) => {
        const currentDate = new Date();
        const [utcHours, utcMinutes] = timeString.split(":").map(Number);
        const utcDate = new Date(Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDate(), utcHours, utcMinutes));
        return utcDate;
      };

    getSlotDetails = (slotName: string) => {
        if (this.state?.slotsDataResponse) {
            const slotDetails = this.state.slotsDataResponse?.find(slotdata => slotdata.day === slotName)
            return typeof slotDetails?.availability === "object" ? slotDetails?.availability.map(timeSlot => ({ startTime: this.formateSlotTime(timeSlot.start_time), endTime: this.formateSlotTime(timeSlot.end_time) })) : []
        }
        return [];
    }

    handleSelectedOverrideDayChange = (value: DayValue) => {
        this.setState({
            selectedOverrideDay: value
        })
        this.GetDayEvents(value);
    }

    handleSelectDateOverrideDialog = (value: DayValue) => {
        this.setState({
            selectedOverrideDayDialog: value
        })
    }

    getInitialValues = (): FormValues => {
        const initialValues = {
            Sunday: {
                selected: this.getSlotDetails("Sunday").length > 0,
                timeSlot: this.getSlotDetails("Sunday") || [],
            },
            Monday: {
                selected: this.getSlotDetails("Monday").length > 0,
                timeSlot: this.getSlotDetails("Monday") || [],
            },
            Tuesday: {
                selected: this.getSlotDetails("Tuesday").length > 0,
                timeSlot: this.getSlotDetails("Tuesday") || [],
            },
            Wednesday: {
                selected: this.getSlotDetails("Wednesday").length > 0,
                timeSlot: this.getSlotDetails("Wednesday") || [],
            },
            Thursday: {
                selected: this.getSlotDetails("Thursday").length > 0,
                timeSlot: this.getSlotDetails("Thursday") || [],
            },
            Friday: {
                selected: this.getSlotDetails("Friday").length > 0,
                timeSlot: this.getSlotDetails("Friday") || [],
            },
            Saturday: {
                selected: this.getSlotDetails("Saturday").length > 0,
                timeSlot: this.getSlotDetails("Saturday") || [],
            },
        };
        return initialValues;
    }

    getSubmitSlotFormatData = (timeSlotData: ITimeSlots[]) => {
        return timeSlotData.map(x => {
            if (x.startTime && x.endTime) {
                return {
                    start_time: this.formateTime(x.startTime.toString()),
                    end_time: this.formateTime(x.endTime.toString()),
                    availability: true,
                }
            }
        })
    }
    

    formatEditTime = (newTime: string, isHours: boolean) => {
        if (isHours) {
            let newHours = parseInt(newTime);
            if (newHours >= 12) {
                newHours -= 12;
            }
            return newHours === 0 ? new Date(new Date(new Date().toUTCString()).setUTCHours(12,0,0,0)).toISOString() : new Date(new Date(new Date().toUTCString()).setUTCHours(newHours,0,0,0)).toISOString()            
        } else {
            return new Date(new Date(new Date().toUTCString()).setUTCMinutes(parseInt(newTime),0,0)).toISOString()
        }
    }

    getInitValues = (condition: EditDODataObject | null) => {
        const does_not_repeat =  {
            "Does_not_repeat":  0,
            "Daily": 1,
            "Custom": 2,
        }
        return (
            {
                title: condition ? condition.title : "",
                unavailable: condition ? condition.unavailable : false,
                startTimeHours: condition?.startHours ? new Date(condition.startHours) : null,
                startTimeMinutes: condition?.startMinutes ? new Date(condition.startMinutes) : null,
                startTimeMeridian: condition?.startMeridian === "a.m." ? { label: "a.m.", value: "a.m." } : { label: "p.m.", value: "p.m." },
                endTimeHours: condition?.endHours ? new Date(condition.endHours) : null,
                endTimeMinutes: condition?.endMinutes ? new Date(condition.endMinutes) : null,
                endTimeMeridian: condition?.endMeridian === "a.m." ? { label: "a.m.", value: "a.m." } : { label: "p.m.", value: "p.m." },
                endTimeValidation: "",
                options: condition 
                    ? this.DateOverridesOptions.find(x => x.value === does_not_repeat[condition?.does_not_repeat])!
                    : this.DateOverridesOptions[0]
            }
        )
    }

    getDOInitialValues = () => {
        const { editDOFormData } = this.state;
        const condition = editDOFormData;

        const repeat: Repeat = {
            Weekly: 0,
            Monthly: 1,
            Yearly: 2
        }

        const newInitialData = this.getInitValues(condition);

        const newCustomInitialData = {
            repeatEvery: condition
                    ? this.DateOverridesCustomOptions.find(value => value.value === repeat[condition.repeat])!
                    : this.DateOverridesCustomOptions[0],
            options: condition ? condition.options : "never",
            endDate: condition?.ends_on && condition.options === "endDate" ? new Date(condition.ends_on) : null,
            occurence: condition?.ends_on && condition.options === "occurence" ? parseInt(condition.ends_on) : null
        }

        this.setState({
            selectedRepeatOn: condition ? condition.repeat_days : [],
            DOInitialValue: newInitialData,
            CustomDOInitialValue: newCustomInitialData
        }) 
    }

    handleEditDOForm = (value: SingleDayDODataObject) => {
        let payload: EditDODataObject = {
            event_date: new Date(moment(value.attributes.event_date, 'MM/DD/YYYY').format("YYYY-MM-DD")),
            title: value.attributes.title,
            unavailable: value.attributes.unavailable,
            does_not_repeat: value.attributes.does_not_repeat,
            repeat: "Weekly",
            repeat_days: [],
            startHours: null,
            startMinutes: null,
            startMeridian: null,
            endHours: null,
            endMinutes: null,
            endMeridian: null,
            ends_on: null,
            options: "never"
        }
        if (!value.attributes.unavailable) {
            payload.startHours = this.formatEditTime(value.attributes.start_time.split(":")[0], true);
            payload.startMinutes = this.formatEditTime(value.attributes.start_time.split(":")[1], false)
            payload.startMeridian = parseInt(value.attributes.start_time.split(":")[0]) >= 12 ? "p.m." : "a.m." 
            payload.endHours = this.formatEditTime(value.attributes.end_time.split(":")[0], true)
            payload.endMinutes = this.formatEditTime(value.attributes.end_time.split(":")[1], false)
            payload.endMeridian = parseInt(value.attributes.end_time.split(":")[0]) >= 12 ? "p.m." : "a.m."
        }
        if (value.attributes.does_not_repeat === "Custom") {
            payload.repeat = value.attributes.repeat
            if (value.attributes.repeat === "Weekly" ) {
                payload.repeat_days = value.attributes.repeat_days || []
            }
            if (value.attributes.ends_on.startsWith("On")) {
                payload.options = "endDate"
                payload.ends_on = value.attributes.ends_on.split("On ")[1]
            } else if (value.attributes.ends_on.startsWith("After")){
                payload.options = "occurence"
                payload.ends_on = value.attributes.ends_on.split(" ")[1]
            } else {
                payload.ends_on = value.attributes.ends_on
            }
        }
        this.setState({
            editDOFormId: value.id,
            addDateOverridesModalOpen: true,
            addCustomOptionsDOModelOpen: payload.does_not_repeat === "Custom" ? true : false,
            editDOFormData: payload,
            selectedOverrideDayDialog: {day: payload.event_date.getDate(), month: payload.event_date.getMonth()+1, year: payload.event_date.getFullYear()}
        }, () => this.getDOInitialValues())
    }

    formatEventScheduledDates = () => {
        if (this.state.AllDayEventData?.length > 0) {
            return this.state.AllDayEventData.map(value => {
                const parsedDate = moment(value.attributes.event_date, 'MM/DD/YYYY');
                const day = parseInt(parsedDate.format('DD'));
                const month = parseInt(parsedDate.format('MM'));
                const year = parseInt(parsedDate.format('YYYY'));
                return { day, month, year, className: "event-day" }
            })
        } else {
            return []
        }
    }

    formateDate = (value: string) => {
        const newDate = new Date(value)
        const parsedDate = moment(newDate, 'MM/DD/YYYY');
        const day = parseInt(parsedDate.format('DD'));
        const month = parseInt(parsedDate.format('MM'));
        const year = parseInt(parsedDate.format('YYYY'));
        return { day, month, year }
    }

    formatPayload = (dayData: IWeekDay) => {
        return dayData.selected ? this.getSubmitSlotFormatData(dayData.timeSlot) : []
    }

    slotsSuccessModalClose = () => {
        this.setState({
            isEditable: false,
            slotsSuccessModal: false
        })
    }

    handleRepeatOn = (dayName: string) => {
        if (this.state.selectedRepeatOn.includes(dayName)) {
            const newData = this.state.selectedRepeatOn.filter(dayname => dayname !== dayName)
            this.setState({
                selectedRepeatOn: newData
            })
        } else {
            this.setState({
                selectedRepeatOn: [...this.state.selectedRepeatOn, dayName]
            })
        }
    }

    handleAddOverridesModalOpen = () => {
        this.setState({
            addDateOverridesModalOpen: true
        })
    }

    handleAddOverridesModalClose = () => {
        this.setState({
            addDateOverridesModalOpen: false,
            DOCustomFormData: null,
            editDOFormId: null,
            DOInitialValue: this.DefaultDOInitialValue,
            CustomDOInitialValue: this.DefaultCustomDOInitialValue,
            selectedRepeatOn: []
        })
    }

    handleAddOverridesCustomOptionModalOpen = () => {
        this.setState({
            addCustomOptionsDOModelOpen: true
        })
    }

    handleAddOverridesCustomOptionModalClose = () => {
        this.setState({
            addCustomOptionsDOModelOpen: false
        })
    }

    formateDateForEventList = (value: string) => {
        return moment(value, 'MM/DD/YYYY').format('Do MMMM YYYY')
    }

    GetDayEvents = async (value?: DayValue) => {
        let formattedDate = `${new Date().getDate()}/${new Date().getMonth()+1}/${new Date().getFullYear()}`
        if (value) {
            formattedDate = `${value.day}/${value.month}/${value.year}`
        } 
        this.getDayEventDataAPICallId = await this.apiCall({
            method: "GET",
            endPoint: `bx_block_formapprovalworkflow/wealth_planner_events?date=${formattedDate}`,
            type: '',
            contentType: "application/json",
        });
    }

    DeleteEvent = async (deleteId: string) => {
        this.deleteEventAPICallId = await this.apiCall({
            method: "DELETE",
            endPoint: `bx_block_formapprovalworkflow/wealth_planner_events/${deleteId}`,
            type: '',
            contentType: "application/json"
        })
    }

    handleDeleteEventConfirm = () => {
        this.setState({
            deleteConfirm: true
        })
    }

    cancelDelete = () => {
        this.setState({
            deleteConfirm: false
        })
    }

    GetAllEvents = async () => {
        this.getAllEventDataAPICallId = await this.apiCall({
            method: "GET",
            endPoint: `bx_block_formapprovalworkflow/wealth_planner_events/get_month_event_dates`,
            type: '',
            contentType: "application/json"
        })
    }

    handleCustomFormDatSubmit = (values: FormikValues) => {
        let option = "";
        switch (values.options) {
            case "endDate":
                option = `On ${moment(values.endDate).format('MMM D, YYYY')}`;
                break;
            case "occurence":
                option = `After ${values.occurence} occurence`;
                break;
            case "never":
            default:
                option = "never";
                break;
        }
        const payload = {
            CustomRepeatEvery: values.repeatEvery.value,
            CustomRepeatOn: this.state.selectedRepeatOn,
            CustomOptions: option
        }
        this.setState({
            DOCustomFormData: payload,
            addCustomOptionsDOModelOpen: false,
        })
    }

    handleDOSubmit = async (values: FormikValues) => {
        const formData = new FormData();
        if (values.options.value === 2 && this.state.DOCustomFormData) {
            formData.append("data[ends_on]", this.state.DOCustomFormData.CustomOptions);
            formData.append("data[repeat]",this.state.DOCustomFormData.CustomRepeatEvery.toString());
            if (this.state.DOCustomFormData.CustomRepeatEvery === 0) {
                for (const dayData of this.state.DOCustomFormData.CustomRepeatOn) {
                    formData.append("data[repeat_days][]", dayData);
                }                
            }
        }
        formData.append("data[does_not_repeat]",values.options.value);
        formData.append("data[unavailable]",values.unavailable);
        if (!values.unavailable) {  
            const startTime =  `${moment(values.startTimeHours).hours()}:${moment(values.startTimeMinutes).minutes()}`
            const endTime =  `${moment(values.endTimeHours).hours()}:${moment(values.endTimeMinutes).minutes()}` 
            formData.append("data[end_time]", moment(`${endTime} ${values.endTimeMeridian.label}`, "HH:mm A").utc().toISOString());
            formData.append("data[start_time]", moment(`${startTime} ${values.startTimeMeridian.label}`, "HH:mm A").utc().toISOString());
        }
        formData.append("data[title]",values.title);
        if (this.state.selectedOverrideDayDialog) {
            formData.append("data[event_date]", moment(`${this.state.selectedOverrideDayDialog.year}-${this.state.selectedOverrideDayDialog.month}-${this.state.selectedOverrideDayDialog.day}`, 'YYYY-M-D').format('DD/MM/YYYY'));
        }

        if (!this.state.editDOFormId) {
            this.postDODataAPICallId = await this.apiCall({
                method: "POST",
                endPoint: `bx_block_formapprovalworkflow/wealth_planner_events`,
                body: formData,
                type: 'formData',
            });
        } else {
            this.putDODataAPICallId = await this.apiCall({
                method: "PUT",
                endPoint: `bx_block_formapprovalworkflow/wealth_planner_events/${this.state.editDOFormId}`,
                body: formData,
                type: 'formData',
                contentType: "formData",
            });
        }
    }

    handleSlotsSubmit = async (values: FormValues) => {
        const payload = {
            weekly_slots: [
                {
                    week_day: 0,
                    slots: this.formatPayload(values.Sunday)
                },
                {
                    week_day: 1,
                    slots: this.formatPayload(values.Monday),
                },
                {
                    week_day: 2,
                    slots: this.formatPayload(values.Tuesday)
                },
                {
                    week_day: 3,
                    slots: this.formatPayload(values.Wednesday)
                },
                {
                    week_day: 4,
                    slots: this.formatPayload(values.Thursday)
                },
                {
                    week_day: 5,
                    slots: this.formatPayload(values.Friday)
                },
                {
                    week_day: 6,
                    slots: this.formatPayload(values.Saturday)
                },
            ]
        }
        this.postSlotsDataAPICallId = await this.apiCall({
            method: "POST",
            endPoint: `bx_block_appointment_management/weekly_availabilities/update_weekly_slots`,
            body: payload,
            type: '',
            contentType: "application/json",
        });
    }

    addSlots = (setFieldValue: (fieldName: string, value: boolean) => void, weekName: keyof FormValues, values: FormikValues) => {
        if(!this.state.isEditable) {
            toast.error("Please click on edit slots first")
        } else {
            setFieldValue(`${weekName}.selected`, true);
            this.addBtnRef[weekName].current?.insert(
                values[weekName].timeSlot.length,
                {
                    startTime: null,
                    endTime: null,
                }
            );
        }
    }

    DayValidation = Yup.object().shape({
        selected: Yup.boolean(),
        timeSlot: Yup.array()
            .of(
                Yup.object().shape({
                    startTime: Yup.date()
                        .nullable()
                        .typeError("Invalid Time Format")
                        .required("Start time is required"),
                    endTime: Yup.date()
                        .nullable()
                        .typeError("Invalid Time Format")
                        .test('is-end-time-after-start-time', 'Invalid slot timings', function(value) {
                            const { startTime } = this.parent;
                            function formateTime(dateString: Date) {
                                const newDate = new Date(dateString);
                                    const options: Intl.DateTimeFormatOptions = {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                        hour12: false,
                                        timeZone: "UTC",
                                    };
                                    return newDate.toLocaleTimeString("en-US", options);
                            }
                            const [startHours, startMinutes] = formateTime(startTime).split(":").map(Number);
                            const [endHours, endMinutes] = formateTime(value).split(":").map(Number);
                            const startDate = new Date(0, 0, 0, startHours, startMinutes);
                            const endDate = new Date(0, 0, 0, endHours, endMinutes);
                        
                            return endDate > startDate;
                        })
                        .required("End time is required"),
                })
            )
            .when("selected", {
                is: true,
                then: Yup.array().min(1, "At least one time slot is required"),
            }),
    })

    validationSchema = Yup.object().shape({
        Sunday: this.DayValidation,
        Monday: this.DayValidation,
        Tuesday: this.DayValidation,
        Wednesday: this.DayValidation,
        Thursday: this.DayValidation,
        Friday: this.DayValidation,
        Saturday: this.DayValidation,
    });

    OverrideValidationSchema = Yup.object().shape({
        title: Yup.string().required("Title is required"),
        unavailable: Yup.boolean(),
        startTimeHours: Yup.date()
          .nullable()
          .when('unavailable', {
            is: false,
            then: Yup.date().typeError("Invalid Time Format").required('Hours are required')
          }),
        startTimeMinutes: Yup.date()
          .nullable()
          .when('unavailable', {
            is: false,
            then: Yup.date().typeError("Invalid Time Format").required('Minutes are required')
        }),
        startTimeMeridian: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string()
        })
        .nullable(),
        endTimeHours: Yup.date()
          .nullable()
          .when('unavailable', {
            is: false,
            then: Yup.date().typeError("Invalid Time Format").required('Hours are required')
          }),
        endTimeMinutes: Yup.date()
          .nullable()
          .when('unavailable', {
            is: false,
            then: Yup.date().typeError("Invalid Time Format").required('Minutes are required')
        }),
        endTimeMeridian: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string()
        })
        .nullable(),
        endTimeValidation: Yup.string().test(
            'is-after-start',
            'End time must be after start time',
            function (value) {
                const getTime = (hours: Date, minutes: Date, period: string) => {
                    let newHours = new Date(hours).getHours();
                    const newMinutes = new Date(minutes).getMinutes();
                    if (period === 'p.m.' && newHours < 12) {
                        newHours += 12;
                    } else if (period === 'a.m.' && newHours === 12) {
                        newHours = 0;
                    }
                    return new Date(0, 0, 0, newHours, newMinutes, 0);
                }
                const { startTimeHours, startTimeMinutes, startTimeMeridian, endTimeHours, endTimeMinutes, endTimeMeridian } = this.parent;
                if (!startTimeHours || !startTimeMinutes || !startTimeMeridian || !endTimeHours || !endTimeMinutes || !endTimeMeridian) {
                    return true;
                }
                const startTime = getTime(startTimeHours, startTimeMinutes, startTimeMeridian.value);
                const endTime = getTime(endTimeHours, endTimeMinutes, endTimeMeridian.value);
              return endTime > startTime;
            }
          )
    });

    CustomOverridesValidationSchema = Yup.object().shape({
        endDate: Yup.date().when('options', {
          is: 'endDate',
          then: Yup.date().typeError("Invalid format").required('End date is required')
        }).nullable(),
        options: Yup.string(),
        occurence: Yup.number().min(1, 'Occurence must be at least 1').nullable().when('options', {
          is: 'occurence',
          then: Yup.number().required('Occurence is required')
        })
      });

    addBtnRef: any = {
        Sunday: React.createRef<FieldArrayRenderProps>(),
        Monday: React.createRef<FieldArrayRenderProps>(),
        Tuesday: React.createRef<FieldArrayRenderProps>(),
        Wednesday: React.createRef<FieldArrayRenderProps>(),
        Thursday: React.createRef<FieldArrayRenderProps>(),
        Friday: React.createRef<FieldArrayRenderProps>(),
        Saturday: React.createRef<FieldArrayRenderProps>(),
    };      

    apiCall = async (data: ApiCallData) => {
        const { contentType, method, endPoint, body, type } = data;
        const header = {
            "Content-Type": contentType,
            token: await storage.get('auhtToken'),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        if (contentType === "formData") {
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify({token: await storage.get('auhtToken')})
            );
        } else {
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
        }

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );

        body && type != 'formData' ?
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            )
            : requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                body
            );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };
    // Customizable Area Start
}