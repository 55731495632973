// Customizable Area Start
import React from "react";

// Customizable Area Start
import {
    Container,
    Box,
    Typography,
    OutlinedInput,
    styled
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import "../assets/border.css";
import FreeTrialController, {
    Props,
} from "./FreetrialController";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from 'react-select';
import { backIcon, plus, card, apple, info, SuccessCard, close } from "./assets";
import CircularProgress from '@material-ui/core/CircularProgress';

// Customizable Area End

// Customizable Area Start
const cashCustomStyles = {
    control: (defaultStyles: any) => (
        {
            ...defaultStyles,
            textAlign: "left",
            fontSize: '16px',
            color: 'rgba(255, 255, 255, 0.60)',
            backgroundColor: "transparent !important",
            border: '1px solid #2DF1E3 !important',
            fontWeight: '500',
            padding: "8px",
            fontFamily: 'Poppins',
            borderRadius: '8px',
        }),
    input: (defaultStyles: object) => ({ ...defaultStyles, color: '#fff' })

};
const theme = createTheme({
    palette: {
        primary: {
            main: "#2CE2D5",
            contrastText: "#fff",
        },
    },
    overrides: {
        MuiOutlinedInput: {
            root: {
                "& $notchedOutline": {
                    borderColor: "#2DF1E3",
                },
                "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
                    borderColor: "#2DF1E3",
                },
                "& .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline": {
                    borderColor: "#2DF1E3 !important",
                },
                "& .MuiOutlinedInput-input": {
                    color: "white",
                    width: '100%',
                    borderBottom: '0px',
                }
            },
        },
    },
});
// Customizable Area End

export default class EmailAccountLogin extends FreeTrialController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    selectMenu(customStyles: any) {
        let value = { value: "", label: "" };
        if (this.state.paymentMethod == 'edit_address') {
            value = { value: this.state.addAddressFormDetails.country, label: this.state.address.attributes.country }
        }
        return (
            <Select
                options={this.state.countryName} defaultValue={value}
                onChange={(e) => this.updateAddAddressDetails('country', e)}
                classNamePrefix="an-simple-select" className="selectMenu" data-test-id="selectMenu" styles={customStyles}
                menuPortalTarget={document.body}
                menuShouldBlockScroll={false}
                maxMenuHeight={120} placeholder="select a country" 
                menuStyle={{ maxHeight: 300 }} />
        )
    }

    formRender() {
        return (
            <Box>
                {
                    this.state.paymentMethod == 'credit_debit_card' &&
                    <>
                        <Box>
                            <Box style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                <img src={card} width={48} />
                                <Typography style={webStyle.formHeader}>Credit/Debit Card</Typography>
                            </Box>
                        </Box>
                        <Box style={{
                            ...webStyle.credit_card_form, display: 'flex',
                            flexDirection: 'column',
                            gap: '20px'
                        }}>
                            <Box style={{ display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'center' }}>
                                <img src={info} width={16} />
                                <Typography style={webStyle.card_message}>You will not be charged for the first trial month, payment will debit from the next month.</Typography>
                            </Box>

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Typography style={webStyle.card_input_message}>Card holder Name</Typography>
                                    <OutlinedInput
                                        style={{ ...webStyle.inputRootForPayments, marginTop: '12px' }}
                                        data-test-id="credit_card_name"
                                        className="inputBox"
                                        fullWidth={true}
                                        value={this.state.paymentMethodAddDetails.cardName}
                                        onChange={(e) => this.updateAddCardDetails('cardName', e.target.value)}
                                        placeholder="Enter Card holder name"
                                        inputProps={{ maxLength: 30 }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography style={webStyle.card_input_message}>Expiry date</Typography>
                                    <OutlinedInput
                                        style={{ ...webStyle.inputRootForPayments, marginTop: '12px', }}
                                        className="inputBox"
                                        data-test-id="credit_card_expiry"
                                        fullWidth={true}
                                        value={this.state.paymentMethodAddDetails.expiry}
                                        onChange={(e) => this.updateAddCardDetails('expiry', e.target.value)}
                                        placeholder="12/22"
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Typography style={webStyle.card_input_message}>Card number</Typography>
                                    <OutlinedInput
                                        style={{ ...webStyle.inputRootForPayments, marginTop: '12px' }}
                                        data-test-id="credit_card_number"
                                        className="inputBox"
                                        fullWidth={true}
                                        value={this.state.paymentMethodAddDetails.cardNumber}
                                        onChange={(e) => this.updateAddCardDetails('cardNumber', e.target.value)}
                                        placeholder="Enter card number"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography style={webStyle.card_input_message}>CVV</Typography>
                                    <OutlinedInput
                                        style={{ ...webStyle.inputRootForPayments, marginTop: '12px', }}
                                        className="inputBox"
                                        data-test-id="credit_card_cvv"
                                        fullWidth={true}
                                        value={this.state.paymentMethodAddDetails.cvv}
                                        onChange={(e) => this.updateAddCardDetails('cvv', e.target.value)}
                                        placeholder="000"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Box data-test-id="updateSaveCardSate">
                                        <label className="container" style={{ paddingLeft: '25px' }} >
                                            <input type="checkbox" checked={this.state.saveCardForFuture} onChange={() => this.updateSaveCardState()} />
                                            <span className="checkmarkOne" style={{ borderColor: "white" }}></span>
                                            <Typography style={webStyle.save_card_msg}>Save this as your primary payment card </Typography>
                                        </label>
                                    </Box>
                                </Grid>

                            </Grid>
                        </Box>
                    </>
                }
                {
                    this.state.paymentMethod.includes('dynamic_card') &&
                    <>
                        <Box>
                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <img src={card} style={{ width: '48px' }} />
                                <Typography style={webStyle.formHeader}>{this.state.selectedPaymentMethod.attributes.card_name + " " + this.state.selectedPaymentMethod.attributes.card_number}</Typography>
                                <Typography style={webStyle.formHeader2}>{`Expires on ${this.state.selectedPaymentMethod.attributes.expires_on}`}</Typography>

                            </Box>
                            <Box style={{
                                ...webStyle.credit_card_form, display: 'flex',
                                flexDirection: 'column',
                                gap: '20px'
                            }}>

                                <Grid container spacing={2} style={{ margin: '15px 10px 0px 10px' }}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography style={webStyle.card_input_message}>Authenticate With CVV</Typography>
                                        <OutlinedInput
                                            style={{ ...webStyle.inputRootForPayments, marginTop: '12px', marginBottom: '16px', }}
                                            className="inputBox"
                                            fullWidth={true}
                                            data-test-id="cvv_input_field"
                                            placeholder="000"
                                            value={this.state.paymentMethodAddDetails.cvv}
                                            onChange={(e) => this.updateAddCardDetails('cvv', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>

                                    </Grid>
                                </Grid>

                            </Box>

                        </Box>
                    </>
                }
                {
                    this.state.paymentMethod == 'add_address' &&
                    <>
                        <Box className="add_address_form">
                            <Box id="add_address_label" style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography style={webStyle.formHeader}>Add Address</Typography>
                            </Box>
                        </Box>
                        <Box style={webStyle.address_form} className="form_body">
                            <Grid container spacing={2} className="add_Address_grid">
                                <Grid item xs={12} id="name_label">
                                    <Typography style={webStyle.card_input_message}>Name:</Typography>
                                    <OutlinedInput
                                        className="inputBox"
                                        style={{ ...webStyle.inputRootForPayments, marginTop: '12px' }}
                                        data-test-id="addNameInput"
                                        placeholder="Enter Name"
                                        onChange={(e) => this.updateAddAddressDetails('name', e.target.value)}
                                        fullWidth={true}
                                    />
                                </Grid>
                                <Grid item xs={12} id="addressFieldLabel">
                                    <Typography style={webStyle.card_input_message}>Address:</Typography>
                                    <OutlinedInput
                                        style={{ ...webStyle.inputRootForPayments, marginTop: '12px' }}
                                        className="inputBox"
                                        fullWidth={true}
                                        data-test-id="addAddressInput"
                                        onChange={(e) => this.updateAddAddressDetails('address', e.target.value)}
                                        placeholder="Enter Address"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} className="cityLabel">
                                    <Typography style={webStyle.card_input_message}>City:</Typography>
                                    <OutlinedInput
                                        style={{ ...webStyle.inputRootForPayments, marginTop: '12px' }}
                                        className="inputBox"
                                        data-test-id="addCityInput"
                                        onChange={(e) => this.updateAddAddressDetails('city', e.target.value)}
                                        fullWidth={true}
                                        placeholder="Enter City"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} className="zipcodeBlock">
                                    <Typography style={webStyle.card_input_message}>Zip Code:</Typography>
                                    <OutlinedInput
                                        style={{ ...webStyle.inputRootForPayments, marginTop: '12px' }}
                                        value={this.state.addAddressFormDetails.zip_code}
                                        data-test-id="addZipCodeInput"
                                        className="inputBox"
                                        placeholder="Max 9 digits"
                                        fullWidth={true}
                                        onChange={(e) => this.updateAddAddressDetails('zip_code', e.target.value)}
                                    />
                                    {
                                        this.state.addressZipCodeInputFieldError && <p style={{
                                            color: '#f22a2a',
                                            margin: 0,
                                            font: 'Poppins',
                                            fontSize: '12px',
                                            fontWeight: 500
                                        }}>Please input atleast 4 digits </p>
                                    }
                                </Grid>
                                <Grid item xs={12} id="countryLabel">
                                    <Typography style={webStyle.card_input_message}>Select Country:</Typography>
                                    <Box style={{ marginTop: '12px' }}>
                                        {(this.selectMenu(cashCustomStyles))}
                                    </Box>
                                </Grid>
                                <Grid item xs={12} >
                                    <Typography data-test-id="addNewAddress" onClick={() => this.setNewAddress()} style={{ ...webStyle.confirmBtnMessage, cursor: 'pointer', float: 'right' }}>Confirm details</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </>
                }
                {
                    this.state.paymentMethod == 'edit_address' &&
                    <>
                        <Box>
                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography style={webStyle.formHeader}>Edit Address</Typography>
                            </Box>
                        </Box>
                        <Box style={webStyle.address_form}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} >
                                    <Typography style={webStyle.card_input_message}>Name:</Typography>
                                    <OutlinedInput
                                        style={{ ...webStyle.inputRootForPayments, marginTop: '12px' }}
                                        data-test-id="editAddressName"
                                        className="inputBox"
                                        fullWidth={true}
                                        onChange={(e) => this.updateAddAddressDetails('name', e.target.value)}
                                        defaultValue={this.state.addAddressFormDetails.name}
                                        placeholder="Enter Name"
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    <Typography style={webStyle.card_input_message}>Address:</Typography>
                                    <OutlinedInput
                                        style={{ ...webStyle.inputRootForPayments, marginTop: '12px' }}
                                        className="inputBox"
                                        data-test-id="editAddressAddress"
                                        fullWidth={true}
                                        onChange={(e) => this.updateAddAddressDetails('address', e.target.value)}
                                        defaultValue={this.state.addAddressFormDetails.address}
                                        placeholder="Enter Address"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    <Typography style={webStyle.card_input_message}>City:</Typography>
                                    <OutlinedInput
                                        style={{ ...webStyle.inputRootForPayments, marginTop: '12px' }}
                                        className="inputBox"
                                        fullWidth={true}
                                        data-test-id="editAddressCity"
                                        onChange={(e) => this.updateAddAddressDetails('city', e.target.value)}
                                        defaultValue={this.state.addAddressFormDetails.city}
                                        placeholder="Enter City"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    <Typography style={webStyle.card_input_message}>Zip Code:</Typography>
                                    <OutlinedInput
                                        style={{ ...webStyle.inputRootForPayments, marginTop: '12px' }}
                                        className="inputBox"
                                        data-test-id="editAddressZipCode"
                                        fullWidth={true}
                                        onChange={(e) => this.updateAddAddressDetails('zip_code', e.target.value)}
                                        value={this.state.addAddressFormDetails.zip_code}
                                        placeholder="Max 9 digits"
                                    />
                                    {
                                        this.state.addressZipCodeInputFieldError && <p style={{
                                            margin: 0,
                                            color: '#f22a2a',
                                            font: 'Poppins',
                                            fontSize: '12px',
                                            fontWeight: 500
                                        }}>Please input atleast 4 digits </p>
                                    }
                                </Grid>
                                <Grid item xs={12} >
                                    <Typography style={webStyle.card_input_message}>Select Country:</Typography>
                                    <Box style={{ marginTop: '12px' }}>
                                        {(this.selectMenu(cashCustomStyles))}
                                    </Box>
                                </Grid>
                                <Grid item xs={12} >
                                    <Typography style={{ ...webStyle.confirmBtnMessage, cursor: 'pointer', float: 'right' }} data-test-id="editConfirmDetails" onClick={() => this.editAddress()}>Confirm details</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </>
                }
            </Box >
        )
    }

    renderEditAddressBox() {
        return (
            <Box>
                <Typography style={this.state.paymentMethod == 'edit_address' ? webStyle.billingAddressFontActive : webStyle.billingAddressFont}>{this.state.address.attributes.name}</Typography>
                <Typography style={this.state.paymentMethod == 'edit_address' ? webStyle.billingAddressFontActive : webStyle.billingAddressFont}>{this.state.address.attributes.address}</Typography>
                <Typography style={this.state.paymentMethod == 'edit_address' ? webStyle.billingAddressFontActive : webStyle.billingAddressFont}>{this.state.address.attributes.city + ", " + this.state.address.attributes.zipcode + ", " + this.state.address.attributes.country}</Typography>

            </Box>
        )
    }

    render() {        
        return (
            // Customizable Area Start
            // Required for all blocks
            <Box className="page_wrapper">
                <Box className="page_container">
                    <Box className="page_header">
                        <Box className="heading_with_navigation_icon">
                            <Box onClick={this.navigateBack} data-test-id="navigateBack">
                                <img src={backIcon} alt="back-icon" />
                            </Box>
                            <Typography style={webStyle.customLabel}>{this.getStringLogin('premium')}</Typography>
                        </Box>
                        <img width={119} src={this.state.logoimg} />
                    </Box>
                    <Grid container spacing={4} dir={this.state.languagesLogin}>
                        <Grid item xs={12} sm={6}>
                            <Box >
                                <Typography style={webStyle.customLabel2}>Summary</Typography>
                                <Box style={{ marginTop: '22px' }}>
                                    <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <Typography style={webStyle.customLabel3}>Your Plan</Typography>
                                        <Typography style={webStyle.customLabel4}>{this.state.selectedPlan1?.attributes?.name}</Typography>
                                    </Box>
                                    <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <Typography style={webStyle.customLabel3}>Payment</Typography>
                                        <Box style={{ display: 'flex', justifyContent: 'center' }}>
                                            {this.state.selectedPlan1?.attributes?.amount == 0 && <>  <Typography style={webStyle.customLabel3}><s>AED 19.99</s></Typography>&nbsp;&nbsp;</>}
                                            <Typography style={webStyle.customLabel4}>{this.state.selectedPlan1?.attributes?.currency?.toUpperCase() + ' ' + this.state.selectedPlan1?.attributes?.amount}</Typography></Box>
                                    </Box>
                                    <hr color="#928F98" />
                                    <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <Typography style={webStyle.customLabel3}>Total Charge</Typography>
                                        <Typography style={webStyle.customLabel5}>{this.state.selectedPlan1?.attributes?.currency?.toUpperCase() + ' ' + this.state.selectedPlan1?.attributes?.amount}</Typography>
                                    </Box>
                                </Box>
                            </Box>

                            {this.state.address ?
                                <Box style={{ marginTop: '24px', }}>
                                    <Box style={webStyle.editAddressBox}>
                                        <Typography style={webStyle.customLabel2}>Billing Address</Typography>
                                        {this.state.paymentMethod != 'edit_address' &&
                                            <Typography style={webStyle.editDetailsFont} data-test-id="editDetailsBtn" onClick={() => this.editDetails()}>Edit details</Typography>
                                        }
                                    </Box>

                                    {this.renderEditAddressBox()}

                                </Box>
                                : <>
                                    <Box style={{ marginTop: '24px', }}>
                                        <Typography style={webStyle.customLabel2}>Billing Address</Typography>
                                        <Box data-test-id="addAddressBtn" onClick={() => { this.checkboxHandle('add_address') }} style={{ ...webStyle.addressBtn, display: 'flex', alignItems: 'center' }}>
                                            <img src={plus} style={{ width: '16px', height: '16px', marginLeft: '10px' }} />
                                            <Typography style={webStyle.customLabel6}>Add new address</Typography>

                                        </Box>

                                    </Box>

                                </>}


                            <Box style={{ marginTop: '24px', }}>
                                <Typography style={webStyle.customLabel2}>Payment Method</Typography>
                                {this.state.paymentMethods &&
                                    this.state.paymentMethods.map((values) => {
                                        return (
                                            <Box style={{ ...webStyle.addressBtn2, display: 'flex', position: 'relative', alignItems: 'center' }} data-test-id={`dynamic_card_${values?.id}`} className={this.state.paymentMethod == `dynamic_card_${values?.id}` ? 'activeBorder' : ""} onClick={() => this.paymentMethodForDynamicCard(values)}>
                                                {values?.attributes.primary_card &&
                                                    <div style={{ position: 'absolute', top: '-11px', right: '23px', background: '#814FF9', borderRadius: '5px', padding: '1px 10px', fontSize: '12px', color: '#fff', }}>Primary</div>
                                                }
                                                <div className="main-radio-box">
                                                    <div className="radio-box2" >
                                                        <input type="radio" className="radio-button" value="search_engine" checked={this.state.paymentMethod === `dynamic_card_${values?.id}`} />
                                                        <label>
                                                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                                <img src={card} style={{ width: '48px' }} />
                                                                <Box>
                                                                    <Typography style={webStyle.cardNameLabel}>{values?.attributes.card_name + ' ' + values?.attributes.card_number}</Typography>
                                                                    <Typography style={webStyle.cardExpireLabel}>{`expires on ${values?.attributes.expires_on}`}</Typography>
                                                                </Box>
                                                            </Box>
                                                        </label></div>
                                                </div>
                                            </Box>
                                        )
                                    })
                                }
                                <Box style={{ ...webStyle.addressBtn2, display: 'flex', alignItems: 'center', cursor: 'pointer' }} data-test-id="credit-debit-card-btn" className={this.state.paymentMethod == 'credit_debit_card' ? 'activeBorder' : ""} onClick={() => this.checkboxHandle('credit_debit_card')}>
                                    <div className="main-radio-box">
                                        <div className="radio-box2" data-test-id="radioBox1"><input type="radio" className="radio-button" value="search_engine" checked={this.state.paymentMethod === "credit_debit_card"} /><label>
                                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                <img src={card} style={{ width: '48px' }} />
                                                <Typography style={webStyle.customLabel7}>Credit/Debit Card</Typography>
                                            </Box>
                                        </label></div>
                                    </div>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box>
                                {this.formRender()}
                            </Box>
                            {(this.state.paymentMethod === "credit_debit_card" || this.state.paymentMethod.includes('dynamic_card')) && <Box data-test-id="completePurchaseBtn" onClick={() => this.completePurchase()}
                                style={{
                                    ...webStyle.confirmPurchaseBtn,

                                }}>
                                <Box>
                                    <Typography style={{ ...webStyle.customLabel7, color: "#3C3C50" }}>complete purchase</Typography>
                                </Box>
                            </Box>}
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Dialog
                        open={this.state.openSuccessAddress}
                        keepMounted
                        data-test-id="dialogModel"
                        onClose={this.dialogCashModel}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                        PaperProps={{ style: this.dialogCashPaperStyle }}
                    >
                        <DialogTitle id="alert-dialog-slide-title" style={{ textAlign: "center" }}><img src={SuccessCard} /></DialogTitle>
                        <DialogContent style={{ padding: '8px 20px' }}>
                            <DialogContentText id="alert-dialog-slide-description" className='dialogContent'>
                                Billing Address updated successfully!
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                    <Dialog
                        open={this.state.openRejectedPopup}
                        keepMounted
                        data-test-id="dialogModel"
                        onClose={this.dialogRejectModel}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                        PaperProps={{ style: this.dialogRejectedCashPaperStyle }}
                    >
                        <DialogTitle id="alert-dialog-slide-title" style={{ textAlign: "center" }}><img src={close} /></DialogTitle>
                        <DialogContent style={{ padding: '8px 20px' }}>
                            <DialogContentText id="alert-dialog-slide-description" className='dialogContent' style={{ textAlign: 'center' }}>
                                Payment Failed,
                                try a different payment method
                                or try again later
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>

                    <Dialog
                        open={this.state.openFreeTrialSuccessPopup}
                        keepMounted
                        data-test-id="dialogModel"
                        onClose={() => this.props.navigation.navigate('Apiintegrationbankaccounts')}
                        aria-labelledby="alert-dialog-slide-title"
                        aria-describedby="alert-dialog-slide-description"
                        PaperProps={{ style: this.dialogCashPaperStyle }}
                    >
                        <DialogTitle id="alert-dialog-slide-title" style={{ textAlign: "center" }}><img src={SuccessCard} /></DialogTitle>
                        <DialogContent style={{ padding: '8px 20px' }}>
                            <DialogContentText id="alert-dialog-slide-description" className='dialogContent' style={{ textAlign: 'center' }}>
                                You're all set
                            </DialogContentText>
                            <DialogContentText id="alert-dialog-slide-description" className='dialogContent' style={{ textAlign: 'center' }}>
                                Your purchase was successful
                            </DialogContentText>
                        </DialogContent>
                    </Dialog>
                    {this.state.isLoader && <LoaderContainer style={{color:"#fff"}}>
        <CircularProgress  size={20} color="inherit"  />
        </LoaderContainer>}
                </Box>
            </Box>

            // Customizable Area End
        );
    }
}

// Customizable Area Start
const LoaderContainer = styled(Box)({
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
    width: "100%",
    height: "100vh",
    position: "absolute",
    top: 0,  // Add top 0 to position correctly
    right: 0,
    zIndex: 9999,
    backgroundColor: "rgba(0, 0, 0, 0.5)",  // Dark background for better visibility
    color: "#fff",
});

const webStyle = {
    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: "red !important",
        }
    },
    save_card_msg: {
        color: 'white',
        fontStyle: 'normal',
        fontSize: '12px',
        fontWeight: 400,
        fontFamily: 'Poppins',
    },
    confirmPurchaseBtn: {
        backgroundColor: '#C9F9F6',
        display: 'flex',
        height: '56px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '10px',
        marginTop: '24px',
        cursor: 'pointer'
    },
    card_input_message: {
        color: 'white',
        fontStyle: 'normal',
        fontSize: '14px',
        fontWeight: 300,
        fontFamily: 'Poppins',
    },
    credit_card_form: {
        borderRadius: '12px',
        border: '1px solid #2DE2D5',
        boxShadow: '0px 0px 10px 0px rgba(44, 226, 213, 0.66)',
        padding: '16px',
        marginTop: '22px',

    },
    address_form: {
        width: '90%',
    },
    customLabel: {
        color: 'white',
        fontStyle: 'normal',
        fontSize: '24px',
        fontWeight: 600,
        fontFamily: 'Poppins',
        marginLeft: "10px",
        marginRight: "10px",
    },
    billingAddressFontActive: {
        color: '#2CE2D5',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: 500,
        fontFamily: 'Poppins',
    },
    billingAddressFont: {
        color: '#928F98',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: 500,
        fontFamily: 'Poppins',
    },
    confirmBtnMessage: {
        color: '#C9F9F6',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: 600,
        fontFamily: 'Poppins',
    },
    card_message: {
        color: 'white',
        fontStyle: 'normal',
        fontSize: '10px',
        fontWeight: 400,
        fontFamily: 'Poppins',
        // margin: '0px 7px',

    },
    customLabel2: {
        color: 'white',
        fontStyle: 'normal',
        fontSize: '20px',
        fontWeight: 600,
        fontFamily: 'Poppins',
    },
    editAddressBox: {
        display: 'flex',
        justifyContent: ' space-between',
        alignItems: "center",
    },
    editDetailsFont: {
        color: '#814FF9',
        fontStyle: 'normal',
        fontSize: '14px',
        fontWeight: 500,
        fontFamily: 'Poppins',
        cursor: 'pointer',
    },
    customLabel3: {
        color: '#928F98',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: 500,
        fontFamily: 'Poppins',
    },
    customLabel4: {
        color: 'white',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: 500,
        fontFamily: 'Poppins',
    },
    customLabel5: {
        color: '#2CE2D5',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: 600,
        fontFamily: 'Poppins',
    },
    customLabel6: {
        color: 'white',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: 600,
        fontFamily: 'Poppins',
        margin: '12px 12px',
    },
    customLabel7: {
        color: 'white',
        fontStyle: 'normal',
        fontSize: '16px',
        fontWeight: 600,
        fontFamily: 'Poppins',
        margin: '12px 7px',
    },
    cardNameLabel: {
        color: 'white',
        fontStyle: 'normal',
        fontSize: '14px',
        fontWeight: 600,
        fontFamily: 'Poppins',
        margin: '0px 7px',

    },
    cardExpireLabel: {
        color: 'rgba(255, 255, 255, 0.50)',
        fontStyle: 'normal',
        fontSize: '10px',
        fontWeight: 400,
        fontFamily: 'Poppins',
        margin: '0px 7px',
    },
    formHeader: {
        color: 'white',
        fontStyle: 'normal',
        fontSize: '20px',
        fontWeight: 600,
        fontFamily: 'Poppins',
        // margin: '12px 7px',
    },
    formHeader2: {
        color: '#928F98',
        fontStyle: 'normal',
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: 'Poppins',
        margin: '12px 7px',
    },


    inputRootForPayments: {
        borderRadius: '10px',
        height: '56px',
        border: 'none',
        fontFamily: 'Poppins',
        '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: '#f44336 !important',
        },
        '& .MuiOutlinedInput-notchedOutline .MuiOutlinedInput-input': {
            padding: '14.5px 14px',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#2DF1E3 !important',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#2DF1E3 !important',
        },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#2DF1E3 !important',
        },
        '& input::placeholder': {
            fontWeight: 500,
            fontSize: '12px',
            color: 'rgba(255, 255, 255, 0.60)',

        }, '& .MuiInputBase-input': {
            borderColor: '#2DF1E3 !important'
        },
    },
    cekbox: {
        '& .MuiCheckbox-root': {
            color: 'aqua',
        },
        '& .MuiIconButton-root': {
            padding: '0px'
        }
    },

    buttons: {
        backgroundColor: "#c7f9f6",
        borderRadius: '10px',
        margin: '0px',
        Color: '#3C3C50',
        height: "50px",
        fontWeight: 700,
        fontSize: '20px',
        textTransform: 'none',
    },
    addressBtn: {
        borderRadius: "12px",
        border: '1px solid #928F98',
        height: '50px',
        marginTop: '22px',
        cursor: 'pointer',
    },
    addressBtn2: {
        borderRadius: "12px",
        border: '1px solid #928F98',
        height: '50px',
        marginTop: '15px',
        cursor: 'pointer',
    },
    logoandimg: {
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center',
        paddingTop: '46px',
        paddingRight: '49px',
        paddingLeft: '49px'
    },
    fieldError: {
        color: "#EE5353",
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '18px',
    }
};
// Customizable Area End
// Customizable Area End
