import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  Grid,
  CssBaseline,
  FormControl,
  Popover,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";

import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import Select from "react-select";
import {
  commodity1,
  commodity2,
  image_Vuesax,
  imagenav_BackIcon,
  threeDot,
} from "./assets";
import CommodityOverviewController, {
  Props,
} from "./CommodityOverviewController.web";
import {  formatNumberWithCommasAndDecimals } from "../../../components/src/commonFn";

const theme = createTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        color: "#94989c",
      },
    },
    MuiDialog: {
      paper: {
        boxShadow: "0px 0px 10px 0px rgba(44, 226, 213, 0.16)",
        backgroundColor: "#395D6B",
        borderRadius: "8px",
        width: "max-content",
      },
    },
    MuiPopover: {
      root: {
        zIndex: 999,
        borderRadius: "14px",
        "&.simple-tooltip .MuiPaper-root": {
          padding: 14,
          boxShadow: "none",
          fontSize: 12,
          letterSpacing: "0.4px",
          backgroundColor: "#395d6b !important",
          borderRadius: "14px",
        },
        "& .MuiPaper-rounded": {
          borderRadius: "0px",
          boxShadow: "none",
        },
      },
    },
  },
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#2CE2D5",
    },
  },
});

// Customizable Area End

export default class CommodityOverview extends CommodityOverviewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <Box style={{ height: "100vh", width: "100%" }} dir={this.state.direction}>
        <Box className="mainWrappers_Box" style={{ minHeight: "100%" }}>
          <Box className="sideBorder">
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "40px 0px",
                }}
              >
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={imagenav_BackIcon}
                    style={{...webStyle.backbtnStyle, transform: this.state.direction === "rtl" ? "rotate(180deg)" : "0deg"}}
                    data-test-id="handle-back"
                    onClick={this.handleBack}
                  />
                  <div
                    style={webStyle.titleTxt}
                  >
                    {this.getStringCommodityOverview("Commodity")}
                  </div>
                </Box>
                <Box className="addCashrightHeaderDropSetting">
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "30px",
                    }}
                  >
                    <FormControl>
                      <Select
                        options={this.state.currencyListDropDown}
                        value={this.state.selectedCurrency}
                        onChange={(value) => {
                          value?.value && this.setCurrency(value);
                        }}
                        classNamePrefix="an-simple-selectUSD"
                        className="selectUSD"
                        data-test-id="selectMenu"
                      />
                    </FormControl>
                    <Box
                      onClick={()=> this.goToAccountDetail()} 
                      style={{ padding: '0px 20px' }} data-test-id="accountDetail"                    >
                      <img
                        src={image_Vuesax}
                        style={{
                          width: "41px",
                          height: "41px",
                          cursor: "pointer",
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Grid container spacing={8}>
                {this.state.commodityData ? this.state?.commodityData.map((commodityData) => {
                  return (
                    <Grid
                      item
                      key={commodityData.id}
                      md={4}
                      sm={6}
                      xs={12}
                      style={{
                        paddingTop: "18px",
                      }}
                    >
                      <Box style={webStyle.container}>
                        <Box style={webStyle.boxContainer}>
                          <img
                            src={
                              commodityData.attributes.commodity_type !== "other"
                                ? commodity2
                                : commodity1
                            }
                            height={55}
                            width={55}
                          />
                          <span
                            style={{
                              position: "absolute",
                              top: 24,
                              right: 24,
                              cursor:"pointer"
                            }}
                            data-test-id="threeDot"
                            onClick={(event) => {
                              this.setCommodityTooltip(event, commodityData);
                            }}
                          >
                            <img src={threeDot} height={14} width={4} />
                          </span>
                          <div
                            style={{
                              [this.state.direction === "rtl" ? 'marginRight' : 'marginLeft']: "12px",
                            }}
                          >
                            <Typography style={webStyle.headingText}>
                              {commodityData.attributes.name}
                            </Typography>
                            <Typography style={webStyle.titleText}>
                              {this.getStringCommodityOverview("Date")}: {commodityData.attributes.transaction_date}
                            </Typography>
                          </div>
                        </Box>
                        <Box
                          style={webStyle.boxContainer1}
                        />
                        <Box
                          style={{
                            padding: "24px",
                          }}
                        >
                          <Grid container>
                            <Grid item md={6} lg={4} >
                              <Typography style={webStyle.titleText}>
                                {this.getStringCommodityOverview("Quantity")}
                              </Typography>
                              <Typography style={webStyle.headingTxt}>
                                {commodityData.attributes.quantity}
                              </Typography>
                            </Grid>
                            <Grid item md={6} lg={4} >
                              <Typography style={webStyle.titleText}>
                                {this.getStringCommodityOverview("PurchasePrice")}
                              </Typography>
                              <Typography style={webStyle.headingTxt}>
                                {formatNumberWithCommasAndDecimals(commodityData.attributes.converted_amount.toString())}
                              </Typography>
                            </Grid>
                            <Grid item md={6} lg={4} >
                              <Typography style={webStyle.titleText}>
                                {this.getStringCommodityOverview("Currency")}
                              </Typography>
                              <Typography style={webStyle.headingTxt}>
                                {commodityData.attributes.converted_currency_type}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </Grid>
                  );
                }): <p style={{
                  width:'100%',
                  fontSize:'16px',
                  fontWeight:500,
                  fontFamily:'Poppins',
                  margin:'3rem 0',
                  textAlign:'center',
                  color:'white'
                }}>{this.getStringCommodityOverview("Nodataavailable")}</p>}
              </Grid>
              <Popover
                open={Boolean(this.state.commodityTooltip)}
                className="simple-tooltip"
                elevation={0}
                anchorEl={this.state.commodityTooltip}
                onClose={this.handleCloseTooltip}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                PaperProps={{
                  style: {
                    backgroundColor: "transparent",
                    marginTop: "10px",
                  },
                }}
              >
                <Box>
                  <Typography
                    style={webStyle.deleteTxt}
                    onClick={() => this.handleEditCommodity()}
                    data-test-id="edit-commodity"
                  >
                    {this.getStringCommodityOverview("Edit")}
                  </Typography>
                  <Typography
                    style={webStyle.deleteTxt}
                    onClick={() => this.handleDeleteCommodity()}
                    data-test-id="delete-commodity"

                  >
                    {this.getStringCommodityOverview("Delete")}
                  </Typography>
                </Box>
              </Popover>
            </ThemeProvider>
          </Box>
        </Box>
        <Dialog
          open={this.state.openDeleteModel}
          keepMounted
          data-test-id="dialogModel"
          onClose={this.closeDeleteModal}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          PaperProps={{ style: this.dialogPaperStyle }}
        >
          <DialogTitle
            id="alert-dialog-slide-title"
            style={webStyle.dialogTitle}
          >
            <span style={webStyle.dialogTitleHeader}>
              {this.getStringCommodityOverview("deleteConfirm")}
            </span>
          </DialogTitle>
          <DialogContent style={webStyle.dialogContent}>
            <DialogContentText
              id="alert-dialog-slide-description"
              style={webStyle.dialogContainer}
            >
              <div className="modal-button-container">
                <div
                  style={webStyle.cancelBtn}
                  className="modal-button modal-save"
                  data-test-id="cancelDelete"

                  onClick={() => this.closeDeleteModal()}
                >
                  {this.getStringCommodityOverview("Cancel")}
                </div>
                <div
                  className="modal-button modal-discard"
                  style={{
                    borderRadius: "7px",
                    height: "40px",
                  }}
                  onClick={()=>this.handleDeleteCommodityAsset()}
                  data-test-id="yesDelete"

                >
                  {this.getStringCommodityOverview("Delete")}
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
type IStyle = { [key: string]: React.CSSProperties };

const webStyle: IStyle = {
  titleText: {
    opacity: 0.5,
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#fff",
  },
  headingText: {
    fontFamily: "Poppins",
    fontSize: "14px",
    color: "#fff",
    fontWeight: 600,
  },
  boxContainer: {
    display: "flex",
    alignItems: "center",
    paddingTop: "24px",
    paddingLeft: "24px",
    paddingRight: "24px",
    paddingBottom: "32px",
  },
  container: {
    background: "#3c3c50",
    borderRadius: "12px",
    position: "relative",
    height: "100%",
    border:"1px solid #57575d"
  },
  dialogTitle: { padding: "0px 65px", marginTop: "3rem" },
  dialogTitleHeader: {
    color: "#fff",
    textAlign: "center",
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: 600,
  },
  dialogContent: {
    padding: "0px!important",
    marginBottom: "0px",
    paddingTop: "15px",
  },
  dialogContainer: {
    color: "rgb(255, 255, 255) !important",
    fontSize: "20px !important",
    fontFamily: "Poppins !important",
    fontWeight: 500,
    marginTop: "18px",
    marginBottom: "2rem",
  },
  deleteTxt:{
    fontSize: "14px",
    color: "#fff",
    cursor: "pointer",
  },
  titleTxt:{
    color: "white",
    padding: "0px 20px",
    fontSize: "28px",
    fontFamily: "Poppins",
    fontWeight: 600,
  },
  backbtnStyle:{
    width: "24px",
    height: "13px",
    cursor: "pointer",
  },
  boxContainer1:{
    height: "1px",
    borderBottom: "1px solid #928f98",
  },
  cancelBtn:{
    borderRadius: "7px",
    height: "40px",
    color: "#c9f9f6",
    border: "1px solid #c9f9f6",
    marginRight: "3rem",
  },
  headingTxt:{
    color: "#2de2d5",
    fontSize: "16px",
    fontFamily: "Poppins",
    fontWeight: 600,
    marginTop:"8px"
  },
};
// Customizable Area End