import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCall } from "../../../components/src/Common";
import { Message } from "../../../framework/src/Message";
import storage from "../../../framework/src/StorageProvider";
import { getStorageData } from "framework/src/Utilities";

// Customizable Area Start
export const configJSON = require("./config");

export interface AllDocumentData {
    gallery_id?: string,
    filename?: string,
    blob_id?: string
    id?: number,
    attributes: {
        folder_name: string,
        gallery?: {
            id?: number
        }
    }
}

interface ConversationGetApi 
{
    message: string,
    conversation: {
        account_sid: string,
        conversation_sid: string,
        chat_service_sid: string,
        messaging_service_sid: string,
        unique_name: string,
        participant1_account_id: string,
        participant1_sid: string,
        participant2_account_id: string,
        participant2_sid: string,
        state: string,
        date_created: string,
        date_updated: string
    }
}

export interface APIPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    type?: string;
    baseURL?: string;
}

export interface EngagementRequest {
    attributes: {
        planner_id: string,
        name: string,
        location: string,
        email: string,
    }
    id: string | number
}

export interface EngagementReject {
    status: string
}

export interface RatingData {
    user_photo: string,
    user_name: string,
    date: string,
    rating: string,
    description: string
}

export interface DataActive {
    attributes: {
        price: string,
        name: string,
        benefits: []
    }
}

export interface PlannerData {
    wealth_planner: {
        data: {
            id: string,
            type: string,
            attributes: {
                planner_id: string,
                name: string,
                location: string,
                jurisdiction_advice: string,
                disclosure: string,
                firm: string,
                employment_history: string,
                qualification: string,
                experience: number,
                certificate: string,
                email: string
                terms_and_condition: true,
                url: string,
                dob: string,
                mobile: number,
                focus: [{/* "name": "Creating a will" */ }],

                image: string,
                request_status: string,
                service_completed_status: string,
                new_services_added: boolean,
                service_added: boolean,
                total_reviews: number,
                average_rating: number,
                rating_and_reviews:
                {
                    rating: number,
                    description: string,
                    user_name: string,
                    date: string,
                    user_photo: string,
                    
                }[],
                plan: string,
                slot: string,
                start_time:any,
                end_time:any,
                slot_cancellation: string,
                pop_up_displayed: boolean,
                allow_access: string,
                review_status: boolean
            }
        }
    }

}

// Customizable Area End
export interface Props {
    navigation: any;
    id: string;
    location: string;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    show: string | undefined,
    isUp: boolean,
    FindData: [],
    showName: boolean,
    isPopupOpenNew: boolean,
    EngagementRequest: any,
    EngagmentReject: any
    isPopupOpenModel: boolean
    permission: null | boolean
    newEngaementService: boolean
    PalnnerId: any
    serviceSatus: boolean
    userSlotData: [],
    isCancelVisible: boolean,
    isRescheduleVisible: boolean,
    dataActive: any,
    isIconClicked: boolean,
    isCancelCallModalOpen: boolean,
    isRescheduleCallModalOpen: boolean,
    currentPlannerId: string,
    statusWithPlannerData: PlannerData | null,
    rating_and_reviews_Model:  {
        "id": string,
        "type": string,
        attributes: {
            date: string,
            description: string,
            rating: number,
            user_name: string,
            user_photo: string,
          }
      }[],
      avgRating: number,
      hover: boolean,
      wealthplannerID:string,
      wealthplannerName:string,
      buttonTextt:string,
      appointment:any,
      toastOpenn:boolean,
      toastOpenedStatus: boolean
      BookingTime:any,
      CurrentTime:any,
      userName:string,
      bookingDuration:number,
      chatApi: ConversationGetApi;
      conversationUser: string;
      conversationId: string;
      hasRedirected:boolean;
      wealthplannerImage:string;
      istimeexceeded:boolean;
      BookingTime2:string;
      direction: string,
      language:string,
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class EngagementRequestwebController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    postUserfinancialPlanner: string = ""
    postUserPDFDataCallId: string = "";
    postUserfinancialPlannerPost: string = "";
    postUserServiceStatus: string = "";
    EngagementRequestPost: string = "";
    postUserfinancialPermissionId: string = "";
    userSlotPlannerID: string = "";
    rescheduleBookingID: string = "";
    cancelBookingID: string = "";
    getStatusWithPlannerIdApiCallId: string = "";
    getAllReviewsCallId:string = "";
    VideoCallApiCallid:string="";
    GetUserInfoApiCallid:string="";
    intervalId: ReturnType<typeof setInterval> | number = 0;
    getConversationApiId:string = "";



    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage)
        ];

        this.state = {
            EngagmentReject: [],
            isPopupOpenNew: false,
            isPopupOpenModel: false,
            permission: null,
            EngagementRequest: [],
            rating_and_reviews_Model: [],
            FindData: [],
            show: "",
            isUp: true,
            newEngaementService: false,
            PalnnerId: {},
            serviceSatus: false,
            showName: false,
            userSlotData: [],
            isCancelVisible: false,
            isRescheduleVisible: false,
            dataActive: [],
            isIconClicked: false,
            isCancelCallModalOpen: false,
            isRescheduleCallModalOpen: false,
            currentPlannerId: "",
            statusWithPlannerData: null,
            avgRating:0,
            hover: false,
            wealthplannerID:'',
            wealthplannerName:"",
            buttonTextt:"Video Call",
            appointment :{
                booking_date: "",
                booking_time: "",
                video_meeting_id: "",
                videosdk_token: ""
              },
            toastOpenn:false,
            toastOpenedStatus: false,
            BookingTime:"",
            CurrentTime:"",
            userName:"",
            bookingDuration:0,
            chatApi: {message: "",
            conversation: {
                account_sid: "",
                conversation_sid: "",
                chat_service_sid: "",
                messaging_service_sid: "",
                unique_name: "",
                participant1_account_id: "",
                participant1_sid: "",
                participant2_account_id: "",
                participant2_sid: "",
                state: "",
                date_created: "",
                date_updated: ""
            }
            },
            conversationUser: "",
            conversationId: "",
            hasRedirected:false,
            wealthplannerImage:"",
            istimeexceeded:false,
            BookingTime2:'',
            direction:'ltr',
            language:"English",
            
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    // Customizable Area Start

    async componentDidMount() {
        const currentPlannerID= await storage.get('currentPlannerId');
        const currentPlannerData= await storage.get("currentPlannerData");
        if(currentPlannerID && currentPlannerData){
        this.setState({
            currentPlannerId: currentPlannerID,
            statusWithPlannerData:JSON.parse(currentPlannerData)
        })}
        this.getSearchParams()
        this.getUserSlot();
        this.getServiceStatus();
        this.meetingIDapiCall();
        this.intervalId = window.setInterval(()=>{this.CalculateDifference()}, 6000);
        let data = await storage.get("selectedBoxData")
        data && this.setState({ dataActive: JSON.parse(data) })
        
        data && this.setState({ dataActive: JSON.parse(data) })

        let language = await getStorageData('language');
        const currentLanguage = language === 'Arabic' ? 'rtl' : 'ltr';
        if(!language){
        language = "English"
        }
        this.setState({ direction: currentLanguage ,language: language});
    }

    getStringEngagementRequest = (keyvalue: string) => {
        return configJSON.languageListEngagementRequest[this.state.language][keyvalue]
    }

    getSearchParams = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const planner_id = urlParams.get('planner_id');
        if (planner_id) {
          this.getStatusWithPlannerId(planner_id)
        }else{
            this.getFinacialList()
        }
      }

    async componentWillUnmount() {
        clearInterval(this.intervalId);
      }

    componentDidUpdate(prevProps:any, prevState:any) {
       
        if (prevState.buttonTextt !== this.state.buttonTextt) {
         
          if (this.state.buttonTextt === 'Join Call') {
           
            this.setState(() => ({
                toastOpenn: true
              }));
           
          }
        }
      }
    meetingIDapiCall = () => {
        const headers = {
            token: localStorage.getItem("authToken"),
            "Content-Type": "application/json",
        };
        const requestMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.VideoCallApiCallid = requestMsg.messageId;
        const getplanner =this.state.currentPlannerId ;
        requestMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `/bx_block_appointment_management/appointments/get_video_call_details?for=customer&planner_id=${getplanner}`
        );
        requestMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMsg.id, requestMsg);
       
    }
    getUserData=()=>{
        const headers = {
            token: localStorage.getItem("authToken"),
           
        };
        const requestMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.GetUserInfoApiCallid = requestMsg.messageId;
        
        requestMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `/bx_block_profile/profiles/show`
        );
        requestMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getApiMethod
        );
        runEngine.sendMessage(requestMsg.id, requestMsg);
    }
    successCallBack = () => {
        const redirectMsg = new Message(getName(MessageEnum.NavigationMessage));
        redirectMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Calender");
        redirectMsg.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(redirectMsg);
    }

    failureCallBack = (response: AllDocumentData) => {
        const redirectMsg = new Message(getName(MessageEnum.NavigationMessage));
        redirectMsg.addData(getName(MessageEnum.NavigationTargetMessage), "Calender");
        redirectMsg.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(redirectMsg);

    }

    getUserSlot = () => {
        const cashData = localStorage.getItem("token")
        const header = { "token": cashData };
        let userDetailurl = configJSON.users_slots;
        const postPdfData = apiCall({
            header: header,
            httpBody: {},
            url: userDetailurl,
            httpMethod: configJSON.send_requests_Get,
        });
        this.userSlotPlannerID = postPdfData.messageId;
        runEngine.sendMessage(postPdfData.id, postPdfData);
    };
    getFinacialList = () => {
        const cashData = localStorage.getItem("token")
        const header = { "token": cashData };
        let userDetailurl = configJSON.send_requests;
        const postPdfData = apiCall({
            header: header,
            httpBody: {},
            url: userDetailurl ,
            httpMethod: configJSON.send_requests_Get,
        });
        this.postUserfinancialPlanner = postPdfData.messageId;
        runEngine.sendMessage(postPdfData.id, postPdfData);
    }
    
    getStatusWithPlannerId = (planner_id:any) => {
        const cashData = localStorage.getItem("token")
        const header = { "token": cashData };
        let userDetailurl = configJSON.send_requests;
        let urlWithPlanner = `${userDetailurl}?planner_id=${planner_id}`
        const postPdfData = apiCall({
            header: header,
            httpBody: {},
            url: urlWithPlanner,
            httpMethod: configJSON.send_requests_Get,
        });
        this.getStatusWithPlannerIdApiCallId = postPdfData.messageId;
        runEngine.sendMessage(postPdfData.id, postPdfData);
    }

    gotoVideo2=()=>{
    
        if (this.state.buttonTextt === 'Join Call') {
            const message = new Message(getName(MessageEnum.NavigationMessage));
            message.addData(getName(MessageEnum.NavigationTargetMessage), "VideoCall");
            message.addData(
                getName(MessageEnum.NavigationPropsMessage),
                this.props
            );
            const raiseMessage: Message = new Message(
                getName(MessageEnum.NavigationPayLoadMessage)
            );
            raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), {
                meetingId: this.state.appointment.video_meeting_id,
                authToken: this.state.appointment.videosdk_token,
                userName: this.state.userName,
                wealthPlanneName: this.state.wealthplannerName,
                wealthPlannerImage: this.state.wealthplannerImage,
                currentPlannerId:this.state.currentPlannerId,
                isCustomer:true,
                BookingTime:this.state.BookingTime2,
                bookingDuration:this.state.bookingDuration
            })
            message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
            this.send(message)
    
        }
  }
    getServiceStatus = () => {
        const cashData = localStorage.getItem("token")
        const header = { "token": cashData };
        let userDetailurl = configJSON.service_status;
        const postPdfData = apiCall({
            header: header,
            httpBody: {},
            url: userDetailurl,
            httpMethod: configJSON.send_requests_Get,
        });
        this.postUserServiceStatus = postPdfData.messageId;
        runEngine.sendMessage(postPdfData.id, postPdfData);
    }
    handleToastClose=()=>{
        this.setState({toastOpenn:false})
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start
        const responseData = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        this.handleApiData(from, message);
        if (this.postUserfinancialPermissionId !== null && this.postUserfinancialPermissionId === responseData) {
            this.getFinacialList()
        }
        else if (this.getConversationApiId !== null && this.getConversationApiId === responseData) {
            if(response){
                this.handleViewChat(response)
            }
        } else if ( this.VideoCallApiCallid === responseData) {
            await this.getMeetingDetails(message);
        } else if ( this.GetUserInfoApiCallid === responseData) {
            await this.getProfileInfo(message);
        } else if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.postUserServiceStatus !== null &&
        this.postUserServiceStatus ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {

        let postUserServiceStatusNew = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        this.setState({ serviceSatus: postUserServiceStatusNew.new_services })

        }
        this.handleReviewResponse(message)

    }
   
    
    handleApiData = async (from: string, message: Message) => {
        const responseData = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (this.postUserfinancialPlanner !== null && this.postUserfinancialPlanner === responseData) {
            await this.handleFinancialPlannerResponse(message);
        } else if (this.userSlotPlannerID === responseData) {
            this.setState({ userSlotData: [] });
        } else if (this.rescheduleBookingID === responseData) {
            await this.handleRescheduleBookingResponse(message);
        } else if (this.cancelBookingID !== null && this.cancelBookingID === responseData) {
            this.getServiceStatus();
        } 
        else if (this.getStatusWithPlannerIdApiCallId !== null && this.getStatusWithPlannerIdApiCallId === responseData) {
             await this.handleFinancialPlannerResponse(message);
        } 
    };
         
    
    async handleFinancialPlannerResponse(message: Message) {
        const EngaementRequestNew = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        this.setState({wealthplannerID:EngaementRequestNew.wealth_planner.data.id,wealthplannerName:EngaementRequestNew.wealth_planner.data.attributes.name,wealthplannerImage:EngaementRequestNew.wealth_planner.data.attributes.image,
            currentPlannerId:EngaementRequestNew.wealth_planner.data.attributes.planner_id
        }, () => {
            this.meetingIDapiCall();
            this.getUserData();
          });
        this.setState({ permission: EngaementRequestNew.access_permission, EngagementRequest: EngaementRequestNew.wealth_planner.data || [], EngagmentReject: EngaementRequestNew || {} });

        if (this.state.EngagmentReject.status === "accepted" && EngaementRequestNew.access_permission === null) {
            this.setState({ isPopupOpenModel: true });
        } else {
            this.setState({ isPopupOpenModel: false });
        }
        this.setState({ PalnnerId: EngaementRequestNew?.wealth_planner?.data?.attributes?.planner_id });
    }
    handleToasttClose5=()=>{

        this.setState({toastOpenn:false})
    }
    extractTime(dateString:any) {
        const date = new Date(dateString);
        const hours = date.getUTCHours().toString().padStart(2, '0');
        const minutes = date.getUTCMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
      }
    async getMeetingDetails(message: Message) {
        const meetingResponce = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    
        const bookingdate = meetingResponce.booking.data.attributes.booking_date;
        const bookingTime = meetingResponce.booking.data.attributes.starts_at;
        const bookingTime2=this.extractTime(bookingTime)

        const meetingID = meetingResponce.booking.data.attributes.video_meeting_id;
        const bookingDuration = meetingResponce.booking.data.attributes.plan_duration;
       const plan_amount = meetingResponce.booking.data.attributes?.plan_amount;
       const plan_name = meetingResponce.booking.data.attributes?.plan_name;
       const plan_benifits = meetingResponce.booking.data.attributes?.plan_benefits;
        const token = meetingResponce.videosdk_token;
    
        // Parse booking date and time as UTC
        const [day, month, year] = bookingdate.split('/').map(Number);
        const [hour, minute] = bookingTime.split(':').map(Number);
        const bookingDateTime = new Date(Date.UTC(year, month - 1, day, hour, minute));
        this.setState({BookingTime2:bookingTime2})
        this.setState(prevState => ({
            appointment: {
                ...prevState.appointment,
                booking_date: bookingdate,
                booking_time: bookingTime,
                video_meeting_id: meetingID,
                videosdk_token: token,
            },
            BookingTime: bookingDateTime,
            BookingTime2:bookingTime2,
            bookingDuration: bookingDuration,
            dataActive: {
                attributes: {
                    price: plan_amount,
                    name: plan_name,
                    benefits: plan_benifits
                }
            }
        }));
        // Calculate difference immediately
        this.CalculateDifference();
    }
    
    async getProfileInfo(message: Message){
        const Profiledata =message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)); 
        this.setState({userName:Profiledata.data.attributes.full_name})
    }
    
    getButtonStyle2=(index:any)=>{
       return(index === 0 ? { marginLeft: '0px' } : {})
    }
    handleToasttClose4=()=>{
        
        this.setState({toastOpenn:false})
    }
    getExperienceLabel = () => {
        const experience = this.state.EngagementRequest.attributes?.experience;
        return experience > 1 ? this.getStringEngagementRequest("Yrs") : this.getStringEngagementRequest("Yr");
      };
      CalculateDifference= () => {
        const { BookingTime, bookingDuration } = this.state;
    
        if (!BookingTime) {
            return;
        }
    
        // Ensure BookingTime is already in UTC
        const bookingDateTime = BookingTime;
    
    
        // Get the current time in UTC
        const currentTime = new Date();
        const currentUTCTime = new Date(Date.UTC(
            currentTime.getUTCFullYear(),
            currentTime.getUTCMonth(),
            currentTime.getUTCDate(),
            currentTime.getUTCHours(),
            currentTime.getUTCMinutes(),
            currentTime.getUTCSeconds()
        ));
    
    
        // Calculate the difference in milliseconds
        const differenceInMillis = (bookingDateTime instanceof Date)
        ? bookingDateTime.getTime() - currentUTCTime.getTime() 
        : 1;
    
        // Convert the difference to minutes
        const differenceInMinutes= Math.floor(differenceInMillis / (1000 * 60));
    


        if (differenceInMinutes <= 5 && differenceInMinutes >= -bookingDuration) {
            if (!this.state.toastOpenedStatus) {
                this.setState({ toastOpenn: true, toastOpenedStatus: true });
            }
            this.setState({  buttonTextt: "Join Call" });
        } else {
            if (differenceInMinutes<=-bookingDuration && !this.state.hasRedirected){
                this.setState({  istimeexceeded:true, toastOpenn: false });
               
              }
              if (differenceInMinutes >= 5){
                this.setState({  buttonTextt: "Video Call",  toastOpenn: false });
              }
           
        }
    };
   
    async handleUserServiceStatusResponse(message: Message) {
        const postUserServiceStatus = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if (postUserServiceStatus.services_selected) {
            this.redirectToSomeRoute('EstatePlanning');
        } else {
            this.redirectToSomeRoute('EngagementRequest/id');
        }
    }

    async handleRescheduleBookingResponse(message: Message) {
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if (responseJson && !responseJson.error) {
            this.successCallBack();
        } else {
            this.failureCallBack(responseJson);
        }
    }

    async handleStatusWithIdResponse(message: Message) {
        const statusData = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                this.setState({ statusWithPlannerData: statusData });
    }

    redirectToSomeRoute = (navRoute: string) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), navRoute);
        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message)
    }


    handleDontAllow = () => {
        const cashData = localStorage.getItem("token")
        const header = {
            "token": cashData,
            "Content-Type": 'application/json',
        };
        let userDetailurl = configJSON.send_allow_access;
        const postPdfData = apiCall({
            header: header,
            httpBody: {
                "wealth_planner_id": this.state.EngagementRequest.attributes.planner_id,
                "permission": false
            },
            url: userDetailurl,
            httpMethod: configJSON.send_allow_access_post,
        });
        this.postUserfinancialPermissionId = postPdfData.messageId;
        runEngine.sendMessage(postPdfData.id, postPdfData);
        this.setState({ isPopupOpenModel: false });
    }

    handleAllow = () => {
        const cashData = localStorage.getItem("token")
        const header = {
            "token": cashData,
            "Content-Type": 'application/json',
        };
        let userDetailurl = configJSON.send_allow_access;
        const postPdfData = apiCall({
            header: header,
            httpBody: {
                "wealth_planner_id": this.state.EngagementRequest.attributes.planner_id,
                "permission": true
            },
            url: userDetailurl,
            httpMethod: configJSON.send_allow_access_post,
        });
        this.postUserfinancialPermissionId = postPdfData.messageId;
        runEngine.sendMessage(postPdfData.id, postPdfData);
        this.setState({ isPopupOpenModel: false });
    }

    showMore = (number: string | undefined, event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        this.setState({ show: number })
        event.stopPropagation()
    }
    handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.stopPropagation();
        this.setState((prevState) => ({
            isUp: !prevState.isUp,
        }));
    }
    dialogCashPaperStyleNew = {
        width: '100%',
        backgroundColor: '#395D6B',
        borderRadius: '8px',
        boxShadow: '0px 0px 10px 0px rgba(44, 226, 213, 0.16)',
    };
    dialogModelPaperStyleNew = {
        width: '30%',
        backgroundColor: '#d9d9d9',
        borderRadius: '8px',
        boxShadow: '0px 0px 10px 0px rgba(44, 226, 213, 0.16)',
    };

    dialogCashModelNew = (event: React.MouseEvent<any>) => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
          this.getAllReviewsCallId = requestMessage.messageId;
          const header = {
            token: localStorage.getItem('token')
          };
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_formapprovalworkflow/rating_and_reviews/all_reviews?wealth_planner_id=${this.state.EngagementRequest.id}`
          );
      
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
          );
      
          runEngine.sendMessage(requestMessage.id, requestMessage);

        event.stopPropagation();
        this.setState({ avgRating: this.state.EngagementRequest.attributes?.average_rating});
    };

    dialogModel = () => {
        this.setState({ isPopupOpenModel: !this.state.isPopupOpenModel });
    };


    handleIconClick = (event: React.MouseEvent<SVGElement>) => {
        event.stopPropagation();
        this.setState((prevState) => ({ showName: !prevState.showName }));
    };



    maskEmail() {
        const email = this.state.EngagementRequest.attributes?.email;
        if (email) {
            const [username, domain] = email.split('@');
            const maskedUsername = '*'.repeat(username.length);
            return `${maskedUsername}@${domain}`;
        }
    }

    goToAppointmentScreen = () => {
        storage.set("currentPlannerId",this.state.EngagementRequest.attributes?.planner_id)
        const message = new Message(getName(MessageEnum.NavigationMessage));

        message.addData(getName(MessageEnum.NavigationTargetMessage), "Appointment");

        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message)

    }

    goToFinancialPlanner() {
        const message: Message = new Message(
            getName(MessageEnum.FinancialPlannerWebAdapter)
        );
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
    }


    handleCancel = () => {
        this.setState({
            isCancelCallModalOpen: true
        })
    }

    handleReschedule = () => {
        this.setState({
            isRescheduleCallModalOpen: true
        })
    }


    handleCancelOnModal = () => {
        if (this.state.isCancelCallModalOpen) {
            this.setState({
                isCancelCallModalOpen: false
            })
        }
        else if (this.state.isRescheduleCallModalOpen) {
            this.setState({
                isRescheduleCallModalOpen: false
            })
        }
    }

    handleCancelOrReschedule = () => {
        if (this.state.isCancelCallModalOpen) {
            this.proceedToCancel()
        }
        else if (this.state.isRescheduleCallModalOpen) {
            storage.set("plannerIdd", this.state.statusWithPlannerData?.wealth_planner.data.attributes.planner_id);
            this.redirectToSomeRoute("RescheduleCalender")
        }
    }

    proceedToCancel = () => {
        const cashData = localStorage.getItem("token")
        const header = {
            "token": cashData,
            "Content-Type": 'application/json',
        };
        let userDetailurl = configJSON.cancelSlot;
        const postPdfData = apiCall({
            header: header,
            httpBody: {
                "planner_id": this.state.EngagementRequest.attributes.planner_id,
            },
            url: userDetailurl,
            httpMethod: configJSON.send_allow_access_post,
        });
        this.cancelBookingID = postPdfData.messageId;
        runEngine.sendMessage(postPdfData.id, postPdfData);

    }

    handleClickNav() {
        localStorage.setItem("plannerId", this.state.PalnnerId)
        this.props.navigation.navigate('EngagementServiceProposal',)
    }
    goToModifyBooking() {
        storage.set("currentPlannerId", this.state.PalnnerId);
        storage.set("currentPlannerData", JSON.stringify(
            {
              "wealth_planner": {
                "data": this.state.EngagementRequest
              }
            }
          ))

        this.props.navigation.navigate("ModifyBooking");
    }
   

    handleDialogClose = () =>{
        this.setState({isPopupOpenNew:false, });
    }

    handleClickNav4() {
        localStorage.setItem("plannerId", this.state.PalnnerId)
        this.props.navigation.navigate('EngagementServiceProposal',)
    }
    handleClickkNav2() {

        localStorage.setItem("plannerId", this.state.PalnnerId)
        this.props.navigation.navigate('EngagementServiceProposal',)
    }
    handleClickkNav3() {

        localStorage.setItem("plannerId", this.state.PalnnerId)
        this.props.navigation.navigate('EngagementServiceProposal',)
    }
   
    handleReviewResponse(message:Message){
    if ((getName(MessageEnum.RestAPIResponceMessage) === message.id &&
        this.getAllReviewsCallId !== null &&
        this.getAllReviewsCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage)))) {

        let response = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        this.setState({ rating_and_reviews_Model: response.reviews.data ,isPopupOpenNew: true })

        }
    }

    handleViewChat = async(chatApi:any) => {
        let chatData =  {
            ...chatApi, 
            plannerID: this.state.EngagementRequest.attributes?.planner_id, 
            userName: this.state.EngagementRequest.attributes?.name, 
            userImage: this.state.EngagementRequest.attributes?.image
        }
        await storage.set('CHAT_DATA', JSON.stringify(chatData));
        const chatMessage: Message = new Message(
          getName(MessageEnum.NavigattionToChatScreen)
        );
        chatMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        chatMessage.addData(getName(MessageEnum.NavigationMessageSendData), chatData);
        this.send(chatMessage);
      }

    apiCallFunction = async (data: APIPayloadType) => {
        let { method, endPoint, body, type = "" } = data;
        const header = { 
            "Content-Type": "application/json",
            token: localStorage.getItem("token")
        };
        let apiRequestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        apiRequestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
        );
        apiRequestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        apiRequestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
        );
        apiRequestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          method
        );
        body && type !== "formData"
          ? apiRequestMessage.addData(
              getName(MessageEnum.RestAPIRequestBodyMessage),
              JSON.stringify(body)
            )
          : apiRequestMessage.addData(
              getName(MessageEnum.RestAPIRequestBodyMessage),
              body
            );
        runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
        return apiRequestMessage.messageId;
      };

      getConversationChat = async () => {
          this.getConversationApiId = await this.apiCallFunction({
              method: configJSON.getApiMethod,
            endPoint: `twilio_chat/create_conversation?type=user&receiver_id=${this.state.EngagementRequest.attributes.planner_id}`
            });
      };

    // Customizable Area End
}
